import React,{useState, useEffect} from 'react'

import {BiChevronRight } from "react-icons/bi";

import bussinessback from '../static/images/bussinessfornow1.PNG'
import flight from "../static/images/Air icon.png";
import AOS from 'aos';
import { Link } from 'react-router-dom';


const HomeBussiness = () => {

    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        AOS.init(
            {
                mirror:false,
                once:true,
                easing:'ease-out',
            }
        );
      }, [])

  return (
    
   <>  
    <div className='w-full  h-full flex gap-4 lg:gap-0 flex-col lg:flex-row overflow-hidden   '>

            <div className=' w-full lg:w-1/2 flex flex-col pt-5'>
                  
                    <div className=' flex  gap-1 pb-5 pl-5 ' data-aos="fade" data-aos-duration="2000" data-aos-offset="200">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="h-12 w-12 md:w-14 md:h-14 lg:w-16 lg:h-16 items-center  animate-pulse hover:scale-125 hover:text-bottom">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      
                        <h2 className='flex lg:text-4xl md:text-3xl text-2xl  items-center hover:scale-110 hover:text-bottom'>Bussiness</h2>
                        
                    </div>
                <img src={bussinessback} alt="icon" className="w-9/12  lg:h-10/12 lg:w-10/12 saturate-150  " />
            </div>
                <div className=' w-full flex p-8 lg:p-0 lg:w-1/2 flex-col justify-center  gap-4 lg:gap-6'>
                    <div className="flex flex-row gap-4 justify-start   " data-aos="fade-left" data-aos-duration="2000" data-aos-offset="200">
                        <img src={flight} alt="icon" className="h-14 w-14 md:h-16 md:w-16  lg:h-20 lg:w-20 hover:scale-125" />
                        <h2 className='flex lg:text-4xl md:text-3xl text-2xl  items-center hover:scale-110 hover:text-bottom' >Travel & Tours</h2>
                    </div>
                   
                    <h4 className='flex justify-start lg:text-lg lg:w-10/12 w-full  md:text-base text-sm font-semibold hover:text-bottom leading-relaxed'data-aos="fade-left" data-aos-duration="2000" data-aos-offset="200">
                    Welcome to Pawpl Travel Management Company, your one-stop destination for all your travel needs. 
                    </h4>
                    <p className='flex text-left lg:text-base md:text-sm text-xs  lg:w-10/12 w-full  leading-relaxed 'data-aos="fade-left" data-aos-duration="2000" data-aos-offset="200">
                        We at PAWPL take pride in being a leading provider of comprehensive 
                        travel services catering to both corporate and leisure travelers. Our commitment 
                        extends to seasoned travelers and newcomers alike, ensuring that everyone experiences the 
                        highest level of service and support.
                    </p>
                    <p className='flex text-left lg:text-base md:text-sm text-xs  lg:w-10/12 w-full  leading-relaxed 'data-aos="fade-left" data-aos-duration="2000" data-aos-offset="200">
                        For our corporate clients, we offer a suite of tailored travel solutions to meet
                        your specific business requirements. Whether it's managing your company's travel
                        policy, organizing group travel, or streamlining your expenses, we've got you covered.
                        Our team of experts understands the unique challenges faced by businesses in today's 
                        fast-paced world and is dedicated to making your corporate travel experience seamless 
                        and efficient.
                    </p>
                    <div className={expanded ? 'block' : 'hidden'} data-aos="fade-left" data-aos-duration="2000" data-aos-offset="200" >
                    <p className='flex text-left pb-4 lg:text-base md:text-sm text-xs  lg:w-10/12 w-full  leading-relaxed 'data-aos="fade-left" data-aos-duration="2000" data-aos-offset="200">
                        For those seeking leisure and adventure, Pawpl Travel is your trusted partner 
                        in crafting unforgettable experiences. From dreamy getaways to thrilling escapades, 
                        our leisure travel services are designed to turn your travel fantasies into reality.
                        Whether you're looking for a relaxing beachfront resort, an epic adventure trek, or 
                        a cultural exploration, our team of travel enthusiasts will curate the perfect 
                        itinerary just for you.
                    </p>
                    <p className='flex text-left pb-4 lg:text-base md:text-sm text-xs  lg:w-10/12 w-full  leading-relaxed 'data-aos="fade-left" data-aos-duration="2000" data-aos-offset="200">
                        But that's not all – at Pawpl Travel, we go the extra mile by offering Meetings,
                        Incentives, Conferences, and Exhibitions (MICE) services. Whether it's a business 
                        conference, a corporate retreat, or an industry expo, we have the expertise to ensure 
                        your event is a resounding success. 
                    </p>
                    <p className='flex text-left pb-4 lg:text-base md:text-sm text-xs  lg:w-10/12 w-full  leading-relaxed 'data-aos="fade-left" data-aos-duration="2000" data-aos-offset="200">
                        Additionally, we understand that travel documentation
                        can be a complex and time-consuming process. That's why we offer visa services 
                        to make your international travel hassle-free. Our dedicated team will assist you in 
                        securing the necessary visas, ensuring you have a smooth journey from start to finish. 
                    </p>
                    <p className='flex text-left pb-4 lg:text-base md:text-sm text-xs  lg:w-10/12 w-full  leading-relaxed 'data-aos="fade-left" data-aos-duration="2000" data-aos-offset="200">
                        Pawpl Travel Management Company is more than just a travel agency; we are your 
                        travel partners. Our commitment to exceptional service, attention to detail, 
                        and passion for travel ensures that every journey with us is an extraordinary experience. 
                        Welcome to Pawpl Travel, where your adventure begins. 
                    </p>
                  
                    </div>
                <button
                    className="w-11/12  gap-0.5 flex justify-end  lg:w-9/12 place-content-end cursor-pointer focus:outline-none text-design text-xs lg:text-base font-normal"
                    onClick={() => setExpanded(!expanded)}
                    data-aos="fade-left" data-aos-duration="2000" data-aos-offset="200"
                    >
                    {expanded ? '...Show Less' : 'Read More...'}
                </button>
              
                <div className='w-11/12  gap-0.5 flex justify-end  lg:w-9/12 place-content-end ' >
                    <Link to="/service" 
                         onClick={() => {
                        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
                        }}
                        className=" w-full justify-end gap-0.5 lg:pl-0 flex text-design text-xs lg:text-base font-normal pt-5 lg:pt-10 lg:leading-6  hover:scale-105 " data-aos="fade-left" data-aos-duration="2000" data-aos-offset="200">
                        Explore Travel Service
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.8} stroke="currentColor" className="lg:w-6 lg:h-6 w-4 h-4 ">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </Link>
            </div> 
            </div>
        </div>
   </>
  )
}

export default HomeBussiness
