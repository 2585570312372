import React, { useState, useEffect } from 'react'

import { BiChevronRight  } from "react-icons/bi";

import foundationbackground from '../static/images/foundationfornow.PNG'

import foundation from "../static/images/imgpsh_fullsize_anim.png";

import AOS from 'aos';
import { Link } from 'react-router-dom';

const HomeShowMore = () => {

    useEffect(() => {
        AOS.init(
            {
                mirror:false,
                once:true,
                easing:'ease-out',
            }
        );
      }, [])
    
    const [isExpanded, setExpanded] = useState(false);

    const toggleAccordion = () => {
        setExpanded(!isExpanded);
    };

  return (
    <>
    
        <div className='w-full h-full lg:py-14 flex flex-col  lg:flex-row overflow-hidden '>
            <div className=' w-full lg:w-1/2 flex flex-col pt-5 overflow-hidden'>
                 <img src={foundationbackground} alt="icon" className="w-9/12  lg:h-10/12 lg:w-10/12 saturate-150 " />
            </div>
            <div className="w-full flex lg:w-1/2 flex-col p-8 lg:p-0 justify-center lg:gap-6 gap-2 overflow-hidden">
                <div className='flex flex-row gap-2 lg:gap-4 justify-start pb-6' data-aos="fade-up" data-aos-duration="2000" data-aos-offset="200">
                    <img src={foundation} alt="icon" className="h-14 w-14 md:h-16 md:w-16 lg:h-20 lg:w-20 hover:scale-125" />
                    <h2 className='flex lg:text-2xl md:text-xl text-xl  items-center hover:scale-105 hover:text-bottom'>PRIYADARSHINI FOUNDATION</h2>   
                </div>
                <p className='flex text-left lg:text-base md:text-sm text-xs  lg:w-10/12 w-full  leading-relaxed ' data-aos="fade-up" data-aos-duration="2000" data-aos-offset="200">
                    Priyadarshini Foundation is a non-profit Trust Registered in
                    belsar Gonda(U.P) which was formed in 2011 by mr.Sunil singh
                    with a goal to empower the under privileged of rural area of
                    Uttar Pradesh we work with citizens and government to improve
                    the quality of life in rural areas. We are for the efforts to
                    help to fill the gap between human rights standards and
                    principals guaranteed by law. the term quality of life as
                    defined by Priyadarshini Foundation is called REED.
                </p>
                <ul className='flex flex-col' data-aos="fade-up" data-aos-duration="2000" data-aos-offset="200">
                    <li className='flex items-center lg:text-base md:text-sm text-xs  lg:w-9/12 w-full leading-relaxed '><BiChevronRight  />Regional perspective to rural issues.</li>
                    <li className='flex items-center lg:text-base md:text-sm text-xs  lg:w-9/12 w-full leading-relaxed '><BiChevronRight  />Empowered citizens and local Government.</li>
                    <li className='flex items-center lg:text-base md:text-sm text-xs  lg:w-9/12 w-full leading-relaxed ' ><BiChevronRight  />Enabled citizens and Local Government.</li>
                    <li className='flex items-center lg:text-base md:text-sm text-xs  lg:w-9/12 w-full leading-relaxed '><BiChevronRight  />Direct Accountability of the Government to the people.</li>
                </ul>
                <h6 className=' flex text-left lg:text-base md:text-sm text-xs  lg:w-9/12 w-full  leading-relaxed hover:text-bottom' data-aos="fade-up" data-aos-duration="2000" data-aos-offset="200">Visit website</h6>
                <a href='http://priyadarshinitrust.org/' target='_blank' className='lg:-mt-6 -mt-2 flex text-left lg:text-base md:text-sm text-xs  lg:w-9/12 w-full  leading-relaxed hover:text-bottom' data-aos="fade-up" data-aos-duration="2000" data-aos-offset="200">http://priyadarshini trust.org/</a>
                <div className='flex justify-end w-11/12 lg:w-9/12 place-content-end pt-10 overflow-hidden 'data-aos="fade-up" data-aos-duration="2000" data-aos-offset="200">
                    <Link to="/service" 
                      onClick={() => {
                        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
                        }}
                    className=" w-full justify-end gap-0.5 lg:pl-0 flex text-design text-xs lg:text-base font-normal pt-5 lg:pt-10 lg:leading-6  hover:scale-110">
                    Explore our CSR Activities
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.8} stroke="currentColor" className="lg:w-6 lg:h-6 w-4 h-4  ">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </Link>
            </div> 

            </div>
            
        </div>
  
    </>
  )
}

export default HomeShowMore
