import React, { useState, useEffect } from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { RxDotFilled } from 'react-icons/rx';
import { RxDot } from 'react-icons/rx';

const ImgaeSlider = () => {
  const slides = [
    {
      url: "https://images.unsplash.com/photo-1530521954074-e64f6810b32d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
    },
    {
      url: "https://images.unsplash.com/photo-1545488286-6fe608f23485?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1331&q=80",
    },
    {
      url: "https://images.unsplash.com/photo-1561131668-f63504fc549d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=857&q=80",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  // Automatic slide movement
  useEffect(() => {
    const intervalId = setInterval(nextSlide, 5000); // Change slide every 5 seconds
    return () => clearInterval(intervalId); // Cleanup the interval when component unmounts
  }, [currentIndex]);

  return (
    <div className='m-auto h-[280px] md:w-11/12 md:h-[350px] lg:max-w-[1045px]   lg:h-[696px]  w-full   lg:pt-12   lg:pb-16  lg:pr-4 pl-0 relative group '>
      <div style={{ backgroundImage: `url(${slides[currentIndex].url})` }} className='w-full h-full lg:w-full lg:h-full rounded-3xl bg-center bg-cover bg-no-repeat duration 500'>
        {/* left arrow */}
        <div className=' hidden group-hover:block absolute top-[50%] -translate-x-0  lg:traslate-y-[-50%] left-2 lg:left-5 text-xl lg:text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
          <BsChevronCompactLeft onClick={prevSlide}  />
        </div>
        
        {/* right arrow */}
        <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 traslate-y-[-50%] right-4 lg:right-8 text-xl lg:text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
          <BsChevronCompactRight onClick={nextSlide}  />
        </div>
        
      </div>
      <div className='flex -mt-6 lg:mt-0 lg:top-4 justify-center self-center lg:py-2  '>
        {slides.map((slide, slideIndex) => (
          <div key={slideIndex} onClick={() => goToSlide(slideIndex)} className='flex justify-center  text-base lg:text-2xl cursor-pointer hover:scale-150 '>
            {/* <RxDotFilled />  */}
           { currentIndex === slideIndex ? <RxDotFilled /> : <RxDot />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImgaeSlider;
