import './App.css';
import { BrowserRouter, Routes } from 'react-router-dom';
import PageRouter from './Routes/PageRouter';

const App = () => {
  return (
    <BrowserRouter>
    <PageRouter/>
    </BrowserRouter>
  );
}

export default App;
