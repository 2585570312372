import React,{useEffect} from 'react';
import aboutusback from '../static/images/Maph.png'
import AOS from 'aos';
import { Link } from 'react-router-dom';

const HomeAboutUs = () => {
    useEffect(() => {
        AOS.init(
            {
                mirror:false,
                once:true,
                easing:'ease-out',
            }
        );
      }, [])

  return (
   <>
        <div className='  w-full h-full pl-8 pb-8 pt-0 pr-8 lg:flex lg:p-24 lg:-mt-6 bg-contain bg-repeat-space md:bg-no-repeat bg-left-bottom md:bg-center overflow-hidden ' id='aboutus' style={{ backgroundImage:`url(${aboutusback})` }} >
            <div className='hidden lg:w-1/2 lg:block'>

            </div>
            <div className=' w-full lg:flex flex-col pt-2 lg:w-1/2 lg:gap-8 text-left  '>
                <div className=' flex flex-row gap-1  pb-5 lg:pb-10' data-aos="fade-left" data-aos-duration="2000" data-aos-offset="200">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className=" h-12 w-12 md:w-14 md:h-14 lg:w-16 lg:h-16 items-center  animate-pulse hover:scale-125 hover:text-bottom">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <h2 className='flex lg:text-4xl md:text-3xl text-2xl  items-center hover:scale-110 hover:text-bottom' >About Us</h2>
                </div>
                <h4 className='flex text-left pb-2 lg:pb-0 lg:text-lg md:text-base text-sm font-semibold hover:text-bottom leading-relaxed' data-aos="fade-left" data-aos-duration="2000" data-aos-offset="200">
                Welcome to Pawpl Group – Priyadarshini Airwings Private limited  
                </h4>
                <p className='flex text-left lg:text-base md:text-sm text-xs  lg:w-10/12 w-full  leading-relaxed ' data-aos="fade-left" data-aos-duration="2000" data-aos-offset="200">
                    Pawpl Group, established in the year 2002, has been on an impressive journey of serving customers
                    across a diverse range of verticals. From the dynamic realms of travel and hospitality to the ever-evolving
                    automobile industry, Pawpl Group has consistently strived to provide top-notch services and solutions. 
                    Our commitment to excellence knows no bounds, extending our support seamlessly from corporate giants to 
                    individual retail clients, and every endeavor in between. With a track record built on innovation, quality, 
                    and customer satisfaction, we continue to evolve and adapt to the ever-changing needs of our clients, ensuring
                    they receive nothing but the best in every interaction with Pawpl Group.
                </p>
              

                <Link to="/service" 
                 onClick={() => {
                    window.scrollTo({top: 0, left: 0, behavior: 'auto'});
                    }}
                className=" w-full  gap-0.5 lg:pl-0 flex text-design text-xs lg:text-base font-normal pt-5 lg:pt-10 lg:leading-6 lg:transform transition ease-in-out delay-75 lg:hover:translate-x-20 hover:scale-125  " data-aos="fade-left" data-aos-duration="2000" data-aos-offset="200" >
                    
                    Read More
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.8} stroke="currentColor" className="lg:w-6 lg:h-6 w-4 h-4 ">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </Link>
            </div>
        </div>
   </>
  )
}

export default HomeAboutUs
