import React,{useState, useEffect} from 'react';
import {BiSolidPhoneCall} from 'react-icons/bi'
import { HiMail } from 'react-icons/hi'
import bike from "../static/images/Bike Icon.png";
import bikebackground from '../static/images/automobilefornow.png'
import AOS from 'aos';
import { Link } from 'react-router-dom';

const HomeAutoMobiles = () => {

    const [expanded, setExpanded] = useState(false);
  
    useEffect(() => {
        AOS.init(
            {
                mirror:false,
                once:true,
                easing:'ease-out',
            }
        );
      }, [])

      const handleEmailClick = () => {
        const emailAddress = 'pawpl.gonda@dealer.roayalenfield.com  ';
        window.location.href = `mailto:${emailAddress}`;
      };
  return (
  <>
    <div className='flex p-8 lg:p-0 w-full h-full overflow-hidden  '>
        <div className='w-full lg:w-1/2 flex  flex-col gap-2 justify-start pt-6  lg:pl-24 overflow-hidden'>
            <div className="w-full flex flex-row gap-4 justify-start  pb-4 lg:pb-8 lg:pt-14 overflow-hidden" data-aos="fade-right" data-aos-duration="2000" data-aos-offset="200">
                <img src={bike} alt="icon" className="flex h-14 w-14 md:h-16 md:w-16 lg:h-20 lg:w-20 hover:scale-125 overflow-hidden" />
                <h2 className='flex lg:text-4xl md:text-3xl text-2xl  items-center hover:scale-110 hover:text-bottom overflow-hidden'>Automobiles</h2>
            </div>  
            <h4 className='flex justify-start lg:text-lg md:text-base text-sm font-semibold hover:text-bottom overflow-hidden'data-aos="fade-right" data-aos-duration="2000" data-aos-offset="200">Introducing Pawpl ventures Ltd.</h4>
            <h4 className='flex pb-4 lg:pb-8 justify-start lg:text-lg md:text-base text-sm font-semibold hover:text-bottom overflow-hidden' data-aos="fade-right" data-aos-duration="2000" data-aos-offset="200">Your Royal enfield dealer in Uttar Pradesh!</h4>
            
            <p className='flex text-left lg:text-base md:text-sm text-xs  lg:w-11/12 w-full  leading-relaxed overflow-hidden 'data-aos="fade-right" data-aos-duration="2000" data-aos-offset="200">
                Pawpl Ventures proudly serves as the distinguished automobile 
                division within the illustrious Pawpl Group. We take immense 
                pride in our role as exclusive dealers for Royal Enfield motorbikes, 
                catering to the passions and aspirations of motorcycle enthusiasts with 
                unparalleled dedication.
            </p>
            <p className='flex text-left lg:text-base md:text-sm text-xs  lg:w-11/12 w-full  leading-relaxed overflow-hidden 'data-aos="fade-right" data-aos-duration="2000" data-aos-offset="200">
                At Pawpl Ventures, we recognize the unique charm and legacy of Royal Enfield, 
                a brand renowned for its timeless craftsmanship and enduring appeal. As exclusive 
                dealers, we offer a curated selection of Royal Enfield motorcycles, each reflecting 
                the brand's rich heritage and unwavering commitment to quality.
            </p>
            <div className={expanded ? 'block' : 'hidden'} data-aos="fade-right" data-aos-duration="2000" data-aos-offset="200">
            <p className='flex text-left pb-4 lg:text-base md:text-sm text-xs  lg:w-11/12 w-full  leading-relaxed overflow-hidden 'data-aos="fade-right" data-aos-duration="2000" data-aos-offset="200">
                Our showroom is a haven for Royal Enfield enthusiasts, where you can explore 
                the entire range of these iconic bikes. Whether you're an adventure seeker, 
                a cruiser aficionado, or simply looking for a classic ride, our dedicated team 
                is here to assist you in finding the perfect Royal Enfield motorcycle that 
                aligns with your riding preferences.
            </p>
            <p className='flex text-left pb-4  lg:text-base md:text-sm text-xs  lg:w-11/12 w-full  leading-relaxed overflow-hidden 'data-aos="fade-right" data-aos-duration="2000" data-aos-offset="200">
                In addition to providing access to these legendary motorcycles, Pawpl Ventures is committed to 
                delivering exceptional customer experiences. We offer comprehensive after-sales services, 
                maintenance, and support, ensuring that your Royal Enfield continues to provide you with 
                unforgettable rides for years to come..
            </p>
            <p className='flex text-left pb-4 lg:text-base md:text-sm text-xs  lg:w-11/12 w-full  leading-relaxed overflow-hidden 'data-aos="fade-right" data-aos-duration="2000" data-aos-offset="200">
                We invite you to visit our showroom and experience the world of 
                Royal Enfield at its finest. Pawpl Ventures is more than just a dealership; 
                it's a destination where your motorcycle dreams come to life. Welcome to Pawpl 
                Ventures, your gateway to the extraordinary world of Royal Enfield.
            </p>
            
            </div>
                <button
                    className="w-11/12  gap-0.5 flex justify-end  lg:w-11/12 place-content-end cursor-pointer focus:outline-none text-design text-xs lg:text-base font-normal"
                    onClick={() => setExpanded(!expanded)}
                    data-aos="fade-right" data-aos-duration="2000" data-aos-offset="200"
                    >
                    {expanded ? '...Show Less' : 'Read More...'}
                </button>
            <div className='flex gap-2 pt-4 cursor-pointer ' data-aos="fade-right" data-aos-duration="2000" data-aos-offset="200">
            <BiSolidPhoneCall  className='md:h-6 md:w-6 h-4 w-4 ' data-aos="fade-right" data-aos-duration="2000" data-aos-offset="200"/> 
            <p className='flex text-left lg:text-base md:text-sm text-xs  lg:w-9/12 w-full  leading-relaxed overflow-hidden 'data-aos="fade-right" data-aos-duration="2000" data-aos-offset="200">
               9606999321
            </p></div>
            <div className='flex gap-2 pt-2' onClick={handleEmailClick} data-aos="fade-right" data-aos-duration="2000" data-aos-offset="200">
                <HiMail className='md:h-6 md:w-6 h-4 w-4' data-aos="fade-right" data-aos-duration="2000" data-aos-offset="200"/>
            <p 
                className=' cursor-pointer flex text-left lg:text-base md:text-sm text-xs  lg:w-9/12 w-full  leading-relaxed overflow-hidden 'data-aos="fade-right" data-aos-duration="2000" data-aos-offset="200"
            >
                pawpl.gonda@dealer.roayalenfield.com  
            </p> </div>
            <div className=' w-full flex justify-end  lg:w-11/12 place-content-end   overflow-hidden ' >
                    <Link to="/service" 
                         onClick={() => {
                            window.scrollTo({top: 0, left: 0, behavior: 'auto'});
                            }}
                       
                        className=" w-full justify-end gap-0.5 lg:pl-0 flex text-design text-xs lg:text-base font-normal pt-5 lg:pt-10 lg:leading-6  hover:scale-125 overflow-hidden" data-aos="fade-right" data-aos-duration="2000" data-aos-offset="200">
                        Explore Automobiles Products
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.8} stroke="currentColor" className="lg:w-6 lg:h-6 w-4 h-4 ">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </Link>
            </div> 
            
         </div>
         
        <div className='hidden lg:flex lg:w-1/2 justify-end overflow-hidden items-start'>
        <img src={bikebackground} alt="icon" className="h-8/12 w-9/12 mt-4 mb-4 overflow-hidden saturate-150 " />

        </div>
    </div>
  </>
  )
}

export default HomeAutoMobiles
