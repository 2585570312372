import React from 'react'
import NavBar from './NavBar'
import Footer from './Footer'

const PrivacyPolicy = () => {
  return (
   <>
    <NavBar />
    <div className='w-full self-center justify-center flex-col p-6 md:p-10 lg:px-40 text-neutral-500 lg:mt-24 md:mt-16 mt-14 '>
        <h4 className='flex text-neutral-900 lg:text-4xl pb-4 md:pb-8 font-semibold md:text-3xl text-2xl  items-center hover:animate-pulse hover:text-bottom py-2 md:py-4 '>Privacy Policy</h4>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Pawpl Group respect your privacy and recognize the need to protect the personally identifiable information (any information by which you can be identified, such as name, address, and telephone number) you share with us. We would like to assure you that we follow appropriate standards when it comes to protecting your privacy on our websites.</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>In general, you can visit Pawpl Group website without telling us who you are or revealing any personal information about yourself. We track the Internet address of the domains from which people visit us and analyze this data for trends and statistics, but the individual user remains anonymous.</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Some of our web pages use "cookies" so that we can better serve you with customized information when you return to our site. Cookies are identifiers which web sites send to the browser on your computer to facilitate your next visit to our site. You can set your browser to notify you when you are sent a cookie, giving you the option to decide whether or not to accept it. The information we collect and analyze is used to improve our service to you.</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>With specific reference to booking/e-commerce transactions, Pawpl Group collect the following personal sensitive information from you while transacting through Pawpl Group:</p>
        <ul>
            <li className=' list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Name and Gender</li>
            <li className='list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Phone Number</li>
            <li className='list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Address</li>
            <li className='list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Credit Card details</li>
            <li className='list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Date of birth in case of a child</li>
        </ul>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Pawpl Group do not sell or trade upon any of the above foregoing information without the consent of the user or customer. The foregoing information collected from the users/customers/travelers is put to the following use:</p>
        <ul>
            <li className=' list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Customer name, address, phone number, traveler’s name and age are shared with applicable service providers like the airlines, hotels, etc., for the purpose of reservation and booking the services for the customer/traveler.</li>
            <li className=' list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Information like Credit Card Details and Net Banking Details are usually collected directly by the payment gateways and banks and not by t skies holidays, but if ever stored or retained by us, remain internal and is never shared, except that they may be shared with third party private/government security agencies to screen for fraudulent activities. These details are also shared with certain third parties only for the purpose of processing 'Cash Back & Discounts' and Charge Backs, if applicable.</li>
            <li className=' list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Information like Mobile no, e-mail address and postal address may be used for promotional purposes, unless the customer/user "opts-out" of such use. If you choose not to share this information, you can still visit the Pawpl Group website, but you may be unable to access certain options, offers and services.</li>
        </ul>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Pawpl Group take appropriate steps to protect the information you share with us. We have implemented technology and security features and strict policy guidelines to safeguard the privacy of your personally identifiable information from unauthorized access and improper use or disclosure. Please contact us on admin@pawpl.com to obtain names and addresses of the specific entities that shall have access to your personal information in a given transaction.</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Pawpl Group will continue to enhance its security procedures as new technology becomes available, and ensures that its security procedures are compliant with current applicable regulations.</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>If our privacy policy changes in the future, it will be posted here and a new effective date will be shown. You should access our privacy policy regularly to ensure you understand our current policies.</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Thank you for using Pawpl Group!</p>
    </div>
    <Footer />
   </>
  )
}

export default PrivacyPolicy
