import React from 'react';

const ContactMap = () => {

   

    const handleDivClick = () => {
        const mapURL = 'https://www.google.com/maps/dir//PAWPL+Ventures+Pvt+Ltd+10%2F28+NK+Plaza+1st+main+4th+N+Block+Dr+Rajkumar+Rd,+Rajajinagar+Bengaluru,+Karnataka+560010/@12.9852697,77.5602951,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae3d84893ac8a9:0x63fe9134a5bc2ecb!2m2!1d77.5602951!2d12.9852697?entry=ttu';
        window.open(mapURL, '_blank');
      };

  return (
    <>
        <div className='w-full bg-gray-400 p-6 md:p-8 mt-4 lg:p-10'>
          <h2 className='w-full sm:px-2 md:px-4 lg:px-8  text-2xl md:text-3xl lg:text-4xl font-semibold leading-loose'>Our Regional Offices</h2>
        </div>
        <div className='w-full  block md:flex sm:px-4 md:px-8 md:pb-8 pb-6  mt-6 md:mt-10 lg:mt-12 lg:px-16 '>
        <div className=' w-full px-6 md:px-0 md:w-4/12 block md:flex md:flex-col '>
           <div className='w-full block md:flex lg:py-8 md:py-6 py-4 lg:pl-8 md:pl-6 pl-4 md:flex-col cursor-pointer border md:border-t-neutral-700 md:border-l-neutral-700 border-neutral-700' onClick={handleDivClick}>
                <h2 className='text-left lg:text-xl md:text-lg text-base leading-relaxed pb-2'>PAWPL Ventures Pvt Ltd</h2>
                <p className='text-left lg:text-lg md:text-base text-sm leading-relaxed'>10/28 NK Plaza 1st main 4th N Block, Dr Rajkumar Rd, Rajajinagar, Bengaluru, Karnataka 560010</p>
                
           </div>
        
        </div>
        <div className='w-full px-6 md:px-0 block md:w-8/12 md:flex md:grow'>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15550.554670679043!2d77.54195788589175!3d12.99494735546309!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3d84893ac8a9%3A0x63fe9134a5bc2ecb!2sPAWPL%20Ventures%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1688462368346!5m2!1sen!2sin"
              id='mappawpl'
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className='w-full'
            />
        </div>
        </div>
    </>
  )
}

export default ContactMap
