import React, { useEffect, useState } from 'react';

const PawplTextEffect = () => {
  const [svgWidth, setSvgWidth] = useState('100%');
  const [svgHeight, setSvgHeight] = useState('100px'); // Initial height
  const [textY, setTextY] = useState('50%'); // Initial Y position
  const [fontSize, setFontSize] = useState('6rem'); // Initial font size
  const [outlineWidth, setOutlineWidth] = useState('.8px'); // Initial outline width
  const [glitterColors, setGlitterColors] = useState([
    '#99FFFF',
    '#00CCCC',
    '#40E0D0',
    '#B0E0E6',
    '#AFEEEE',
    '#B2FFFF',
  ]);

  useEffect(() => {
    // Update SVG dimensions, text Y position, and font size based on window size
    function updateSvgAndText() {
      setSvgWidth('100%');

      if (window.innerWidth >= 1024) {
        setSvgHeight(window.innerHeight * 0.33 + 'px');
        setTextY('78%');
        setFontSize(window.innerWidth * 0.12 + 'px');
        setOutlineWidth(`clamp(.8px, ${window.innerWidth * 0.01}vw, 1.8px)`);
      } else if (window.innerWidth >= 768) {
        setSvgHeight(window.innerHeight * 0.18 + 'px');
        setTextY('80%');
        setFontSize(window.innerWidth * 0.13 + 'px');
        setOutlineWidth(`clamp(.8px, ${window.innerWidth * 0.01}vw, 1.7px)`);
      } else if (window.innerWidth >= 640) {
        setSvgHeight(window.innerHeight * 0.10 + 'px');
        setTextY('78%');
        setFontSize(window.innerWidth * 0.13 + 'px');
        setOutlineWidth(`clamp(.8px, ${window.innerWidth * 0.01}vw, 1.6px)`);
      } else {
        setSvgHeight(window.innerHeight * 0.12 + 'px');
        setTextY('78%');
        setFontSize('4rem');
        setOutlineWidth('.8px');
      }
    }

    // Attach event listener for window resize
    window.addEventListener('resize', updateSvgAndText);

    // Initial call to set dimensions, text Y position, and font size
    updateSvgAndText();

    // Clean up event listener
    return () => {
      window.removeEventListener('resize', updateSvgAndText);
    };
  }, []);

  // Generate keyframes for glitter animation
  const generateGlitterKeyframes = () => {
    const keyframes = glitterColors.map((color, index) => {
      const percentage = (index / glitterColors.length) * 100;
      return `
        ${percentage}% {
          stroke: ${color};
        }
      `;
    });
    return keyframes.join('');
  };

  return (
    <div className="patterns">
      <style>
        {`
          body, html {
            margin: 0;
            padding: 0;
          }

          @font-face {
            font-family: "blippo-bt-black";
            src: url("../static/font/blippo-bt-black.ttf"); 
          
          }

          .btblack {
            font-family: "blippo-bt-black";
            font-size: ${fontSize}; 
            font-weight: 900;
            text-transform: lowercase;
            fill: transparent;
            stroke: #1b1464; /* Outline color */
            stroke-width: ${outlineWidth}; /* Outline width */
            animation: textDraw 5s linear forwards, glitter 5s forwards;
          }

          @keyframes textDraw {
            0% {
              stroke-dasharray: 0 100%;
              stroke-dashoffset: 100%;
            }

            100% {
              stroke-dasharray: 100% 0;
              stroke-dashoffset: 0;
            }
          }

          @keyframes glitter {
            ${generateGlitterKeyframes()}
          }
        `}
      </style>
      <svg width={svgWidth} height={svgHeight}> 
        <text className="btblack absolute left-0 transform origin-left" x="0%" y={textY}>
          Pawpl
        </text>
      </svg>
    </div>
  );
};

export default PawplTextEffect;
