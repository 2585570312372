import React from 'react'
import { Route,  Routes  } from 'react-router-dom'
import HomePage from '../pages/HomePage/HomePage'
import Service from '../pages/ServicePage/Service'
import Contact from '../pages/ContactPage/Contact'
import PrivacyPolicy from '../components/PrivacyPolicy'
import TermsOfUse from '../components/TermsOfUse'


const PageRouter = () => {
  return (

    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/service" element={<Service />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/terms" element={<TermsOfUse />} />
    </Routes>

  )
}

export default PageRouter
