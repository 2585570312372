import { Fragment, useState } from 'react'
import logoimage from "../static/images/Pawpl_logo.png";
import { Dialog } from '@headlessui/react';

import {
    XMarkIcon,
    Bars3Icon,
  
  } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom';

const NavBar = () => {

    
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  return (
  <>
     <header className="bg-white w-full fixed top-0 z-10">
        <nav className=" mx-auto flex justify-between max-w-8xl lg:items-center   p-2 lg:px-8" aria-label="Global">
            <div className=" w-full  lg:w-2/12 lg:items-center items-start lg:flex-1 ">
                <a href="#" className="m-0  ">
                    <img className="h-14  lg:h-1/5 lg:w-auto " src={logoimage} alt="" />
                </a>
            </div>
        
        <div className="hidden w-10/12 justify-center lg:flex lg:gap-x-12">
            <div >
            <Link to="/" 
             onClick={() => {
                window.scrollTo({top: 0, left: 0, behavior: 'auto'});
                }}
            className="flex text-base gap-0.5 font-semibold leading-6 text-gray-900  hover:scale-125">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.8} stroke="currentColor" className="w-6 h-6 ">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
                Home
            </Link>
            </div>  
            {/* <div>
            <Link to="/service" 
             onClick={() => {
                window.scrollTo({top: 0, left: 0, behavior: 'auto'});
                }}
            className=" flex text-base gap-0.5 font-semibold leading-6 text-gray-900  hover:scale-125">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.8} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
                About us
            </Link>
            </div>  */}
            <div>
            <Link to="/service" 
             onClick={() => {
                window.scrollTo({top: 0, left: 0, behavior: 'auto'});
                }}
            className=" flex text-base gap-0.5 font-semibold leading-6 text-gray-900  hover:scale-125">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.8} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
                Bussiness
            </Link>
            </div> 
         
            <div>
            <Link to="/contact" 
             onClick={() => {
                window.scrollTo({top: 0, left: 0, behavior: 'auto'});
                }}
            className=" flex gap-0.5 text-base font-semibold leading-6 text-gray-900 hover:scale-125">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.8} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
                Contact Us
            </Link>
            </div> 
        </div>
        <div className="flex lg:hidden relative justify-end self-end ">
            <button
                type="button"
                className="-m-2.5 inline-flex items-end justify-center rounded-md p-2.5 text-gray-700 ml-auto"
                onClick={() => setMobileMenuOpen(true)}
            > 
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-8 w-8 lg:h-10 lg:w-10 " aria-hidden="true" />
            </button>
        </div>
        </nav>
        <Dialog as="div" className="lg:hidden fixed   " open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <div className="fixed inset-0 z-10" >
            <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full md:w-full h-fit  overflow-y-auto bg-white px-6 py-10 rounded-xl  sm:ring-1 sm:ring-gray-900/10">
                <div className="flex mt-8 items-center justify-between">
                    <a href="#" className="-m-1.5 p-1.5  ">
                        <img className="h-12 w-auto " src={logoimage} alt="" />
                    </a>
                    <button
                        type="button"
                        className="-m-2.5 rounded-md p-2.5 text-gray-700"
                        onClick={() => setMobileMenuOpen(false)}
                        >
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className=" h-6 w-6 md:h-10 md:w-10" aria-hidden="true" />
                    </button>
                </div> 
                <div className="mt-6 flow-root">
                    <div className="-my-6 divide-y divide-gray-500/10">
                        <div className="space-y-2 py-6">
                            <div>
                                <Link to="/"
                                 onClick={() => {
                                    window.scrollTo({top: 0, left: 0, behavior: 'auto'});
                                    }}
                                className=" flex gap-0.5 text-base font-semibold leading-6 text-gray-900   hover:scale-105">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.8} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                    Home
                                </Link>
                            </div>  
                            {/* <div>
                                <Link to="/service" 
                                 onClick={() => {
                                    window.scrollTo({top: 0, left: 0, behavior: 'auto'});
                                    }}
                                className=" flex gap-0.5 text-base font-semibold leading-6 text-gray-900  hover:scale-100">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.8} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                    About us
                                </Link>
                            </div>  */}
                            <div>
                                <Link to="/service" 
                                 onClick={() => {
                                    window.scrollTo({top: 0, left: 0, behavior: 'auto'});
                                    }}
                                className=" flex gap-0.5 text-base font-semibold leading-6 text-gray-900  hover:scale-105">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.8} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                    Bussiness
                                </Link>
                            </div> 
                       
                            <div>
                                <Link to="/contact" 
                                 onClick={() => {
                                    window.scrollTo({top: 0, left: 0, behavior: 'auto'});
                                    }}
                                className=" flex gap-0.5 text-base font-semibold leading-6 text-gray-900  hover:scale-105">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.8} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                    Contact Us
                                </Link>
                            </div> 
                        </div>
                    </div>
                </div>
                    
            </Dialog.Panel>
            </div>
        </Dialog>
     </header>
  </>
  )
}

export default NavBar
