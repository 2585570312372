import React from 'react'

import expirienceimage from "../static/images/s1.jpg";
import serviceimage from "../static/images/service.jpg";
import expertiseimage from "../static/images/experties.jpg";
import supportimage from "../static/images/support.jpg";
import AOS from 'aos';
import { useEffect } from "react";

const HomeWhyChooseUs = () => {
  useEffect(() => {
    AOS.init(
        {
            mirror:false,
            once:true,
            easing:'ease-out',
            
        }
    );
  }, [])

  return (
  <>
    <div className='flex w-full h-full md:p-12 p-8 lg:p-14 overflow-hidden '>
        <div className='flex flex-col gap-4 overflow-hidden '>
            <div className="flex flex-row items-center gap-1 lg:gap-2  overflow-hidden " data-aos="fade-right" data-aos-duration='3000' data-aos-offset="200">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="lg:w-16 lg:h-16 md:h-14 md:w-14 h-12 w-12 items-center  animate-pulse hover:scale-125 hover:text-bottom">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <h2 className='flex lg:text-4xl md:text-3xl text-2xl  items-center hover:scale-105 hover:text-bottom overflow-hidden'>Why Choose Us</h2>
            </div>
            <p className='flex text-center lg:text-base md:text-sm text-xs   items-center justify-center leading-relaxed overflow-hidden' data-aos="fade-right" data-aos-duration='3000' data-aos-offset="200">
                For over 2 decades, we have been into travel business serving
                customers of all segments be it corporate travel, Leisure travel our
                customers always trusted on us for our experties, quality and
                customer services. We've worked with private firms, government
                agencies and business owners from a range of industries to transform
                their travel experience.
          </p>
          <hr className='flex bg-gray-900  w-3/5 h-0.5 self-center '></hr>
          <h2 className='flex pb-4 lg:pb-8 lg:text-2xl md:text-xl text-lg justify-center  items-center hover:scale-105 hover:text-bottom overflow-hidden' data-aos-duration="3000" data-aos-offset="200" data-aos="fade-right">Why Choose Pawpl Travel Consultants?</h2>
          <div className='w-full lg:flex justify-evenly lg:pb-12 overflow-hidden'>
            <div className='w-full flex lg:w-3/5 self-center overflow-hidden' data-aos-duration="3000" data-aos-offset="200" data-aos="fade-right">
                 <img src={expirienceimage} alt="image" className="w-11/12   lg:h-80 md:h-64 h-full rounded-2xl overflow-hidden" data-aos-duration="3000" data-aos-offset="200" data-aos="fade-right"    />
            </div>
            <div className='w-full flex flex-col lg:w-1/3  gap-2 justify-start self-center overflow-hidden'data-aos-duration="3000" data-aos-offset="200" data-aos="fade-left">
                  <h2 className='flex  pt-4 lg:pt-0 lg:text-xl md:text-lg text-base justify-start  items-center hover:scale-105 hover:text-bottom' data-aos-duration="3000" data-aos-offset="200" data-aos="fade-left"> EXPERIENCE</h2>
                  <p className='flex text-left lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed overflow-hidden ' data-aos-duration="3000" data-aos-offset="200" data-aos="fade-left">
                    With over two decades of experience in the travel industry, we
                    have a deep understanding of our customers needs and preferences.
                  </p>
            </div>
          </div>
          <div className=' flex flex-col-reverse lg:flex-row justify-evenly lg:pb-12 overflow-hidden '>
           
            <div className='w-full flex flex-col lg:w-1/3  gap-2 lg:justify-end self-center overflow-hidden' data-aos-duration="3000" data-aos-offset="200" data-aos="fade-right">
                  <h2 className='w-full flex pt-4 lg:pt-0 lg:text-xl md:text-lg text-base lg:justify-end  items-center hover:scale-105 hover:text-bottom overflow-hidden'data-aos-duration="3000" data-aos-offset="200" data-aos="fade-right">PERSONALISED SERVICE</h2>
                  <p className='w-full flex  text-left lg:text-right lg:text-base md:text-sm text-xs  lg:justify-end items-center  leading-relaxed overflow-hidden 'data-aos-duration="3000" data-aos-offset="200" data-aos="fade-right">
                  We believe in building lasting relationships with our clients. Our
                   dedicated team provides personalized attention and cutomizes every
                  aspect of your travel experience.
                  </p>
            </div>
            <div className='w-full flex lg:w-3/5 lg:justify-end self-center overflow-hidden' data-aos-duration="3000" data-aos-offset="200" data-aos="fade-left">
                 <img src={serviceimage} alt="image" className="w-11/12 lg:h-80 md:h-64 h-full rounded-2xl overflow-hidden" data-aos-duration="3000" data-aos-offset="200" data-aos="fade-left"   />
            </div>
          </div>
          <div className='w-full lg:flex justify-evenly lg:pb-12 overflow-hidden'>
            <div className='w-full flex lg:w-3/5 self-center overflow-hidden' data-aos-duration="3000" data-aos-offset="200" data-aos="fade-right">
                 <img src={expertiseimage} alt="image" className="w-11/12 lg:h-80 md:h-64 h-full rounded-2xl overflow-hidden" data-aos-duration="3000" data-aos-offset="200" data-aos="fade-right"   />
            </div>
            <div className='w-full flex flex-col lg:w-1/3  gap-2 justify-start self-center overflow-hidden' data-aos-duration="3000" data-aos-offset="200" data-aos="fade-left">
                  <h2 className='flex  pt-4 lg:pt-0 lg:text-xl md:text-lg text-base justify-start  items-center hover:scale-105 hover:text-bottom overflow-hidden' data-aos-duration="3000" data-aos-offset="200" data-aos="fade-left"> EXPERTISE</h2>
                  <p className='flex text-left lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed overflow-hidden 'data-aos-duration="3000" data-aos-offset="200" data-aos="fade-left">
                    Our travel consultants are well-versed in destinations worldwide.
                    We stay updated with the latest trends, ensuring that you receive
                    accurate information and the best travel options.
                  </p>
            </div>
          </div>
          <div className='flex flex-col-reverse lg:flex-row  justify-evenly lg:pb-4 overflow-hidden'>
           
            <div className='w-full flex flex-col lg:w-1/3  gap-2 lg:justify-end self-center overflow-hidden' data-aos-duration="3000" data-aos-offset="200" data-aos="fade-right" >
                  <h2 className='w-full flex pt-4 lg:pt-0 lg:text-xl md:text-lg text-base lg:justify-end overflow-hidden items-center hover:scale-105 hover:text-bottom' data-aos-duration="3000" data-aos-offset="200" data-aos="fade-right" > 24/7 SUPPORT</h2>
                  <p className='w-full flex  text-left lg:text-right lg:text-base md:text-sm text-xs  lg:justify-end items-center leading-relaxed overflow-hidden ' data-aos-duration="3000" data-aos-offset="200" data-aos="fade-right">
                    we are committed to providing exceptional customer service.Our
                    support team is available round-the-clock to assist you with any
                    queries or concerns during your travels.
                  </p>
            </div>
            <div className='w-full flex lg:w-3/5 lg:justify-end self-center overflow-hidden'  data-aos-duration="3000" data-aos-offset="200" data-aos="fade-left" >
                 <img src={supportimage} alt="image" className="w-11/12 lg:h-80 md:h-64 h-full rounded-2xl overflow-hidden" data-aos-duration="3000" data-aos-offset="200" data-aos="fade-left"    />
            </div>
          </div>
          
        
        </div>
    </div>
  </>
  )
}

export default HomeWhyChooseUs
