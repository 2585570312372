import React ,{useEffect}from 'react';
import {BiChevronRight} from 'react-icons/bi'
import peopleimg from "../static/images/people.jpg";
import gridback from '../static/images/charitybackground.png';
import AOS from 'aos';
import { useParams } from 'react-router-dom'; 


const ServiceThreeCharitable = () => {
   

    useEffect(() => {
        AOS.init(
            {
                mirror:false,
                once:true,
                easing:'ease-out',
            }
        );
      }, [])


  return (
    <>
        <div className='w-full h-full flex flex-col-reverse md:flex-row md:flex p-8 lg:p-12 overflow-hidden ' >
            <div className='flex  w-full md:w-8/12 justify-center self-center overflow-hidden' data-aos-duration="2000" data-aos-offset="200" data-aos="zoom-out">
                <img src={peopleimg} alt="peopleimg" className="w-12/12 md:w-9/12 pt-4 md:pt-0 saturate-200 " data-aos-duration="2000" data-aos-offset="200" data-aos="zoom-out"/>
            </div>
            <div className='w-44 text-left md:self-center flex md:w-48 lg:w-80   flex-col h-full border-2 border-blue-400 p-4  md:p-8 lg:p-12 rounded-3xl overflow-hidden'>
                <h4 className='w-full  md:flex text-blue-400 text-3xl md:text-3xl lg:text-5xl  font-bold md:pb-1 lg:pb-4 pt-4' >3.</h4>
                <h4 className='w-full md:flex  text-blue-400 text-2xl md:text-2xl lg:text-4xl  font-bold  md:leading-10'>Charitable</h4>
                <h4 className='w-full md:flex text-blue-400 text-2xl md:text-2xl lg:text-4xl  font-bold md:pb-4 md:leading-10' >Services</h4>
            </div>
            
        </div>
        <div className='flex px-8 md:px-14 lg:px-28 overflow-hidden ' >
                <p className=" font-normal  lg:text-base md:text-sm text-xs text-center leading-relaxed" data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">
                    We see that people from rural areas are migrating to urban areas in
                    search of opportunities resulting loss of roral talent. We should take
                    more initiatives for rural development.
                </p>
        </div>
        <div className='w-full h-full pb-4  lg:flex-col lg:bg-no-repeat bg-left bg-contain overflow-hidden' style={{ backgroundImage:`url(${gridback})` }}>
            <div className='flex w-full flex-col lg:flex-row p-2 justify-evenly overflow-hidden '>
                <div className='w-11/12 self-center mb-2 lg:mb-0  md:w-8/12 lg:flex lg:flex-col px-4 pb-4 bg-blue-400 lg:w-72 lg:h-80' >
                    <h2 className='flex bth2 self-center  ' data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">education</h2>
                    <p className='flex text-white font-normal  lg:text-base md:text-sm text-xs text-left leading-relaxed' data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">
                    Basic education increases our efficiency as with no education we
                    can only manage to do simple manual work and find it difficult to
                    adopt more advanced production process and techniques.
                    </p>
                </div>
                <div className='w-11/12 self-center mb-2 lg:mb-0  md:w-8/12 lg:flex lg:flex-col px-4 pb-4 bg-sky-400 lg:w-72 lg:h-80' >
                    <h2 className='flex bth2 self-center' data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">health</h2>
                    <p className='flex text-white font-normal  lg:text-base md:text-sm text-xs text-left leading-relaxed' data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">
                    Health is vital for overall development poor health leads to low
                    productivity rural health care is worse then Urban Health Care
                    they faces barriers as transportation difficulties, limited health
                    care supply, lack of quality health care, social isolation and
                    financial constraints.
                    </p>
                </div>
                <div className='w-11/12 self-center mb-2 lg:mb-0  md:w-8/12 lg:flex lg:flex-col px-4 pb-4 bg-cyan-500 lg:w-72 lg:h-80'>
                    <h2 className='flex bth2 self-center' data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">Shelter</h2>
                    <p className='flex text-white font-normal  lg:text-base md:text-sm text-xs text-left leading-relaxed' data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">
                    Rural Housing has wider gap and in general suffered the lack of
                    planned Government resource mobilization and the implementation
                    programmes although the government has under taken many
                    initiatives to promote rural housing as per data U.P is the fourth
                    state in the country in term of rural homelessness.
                    </p>
                </div>
                <div className='w-11/12 self-center mb-2 lg:mb-0  md:w-8/12 lg:flex lg:flex-col px-4 pb-4 bg-blue-300 lg:w-72 lg:h-80'>
                    <h2 className='flex bth2 self-center 'data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">employment</h2>
                    <p className='flex text-white font-normal  lg:text-base md:text-sm text-xs text-left leading-relaxed' data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">
                    Rural Housing has wider gap and in general suffered the lack of
                    planned Government resource mobilization and the implementation
                    programs although the government has under taken many initiatives
                    to promote rural housing as per data U.P is the fourth state in
                    the country in term of rural homelessness.
                    </p>
                </div>

            </div>
            <div className='flex w-full flex-col lg:flex-row p-2 justify-evenly overflow-hidden '>
                <div className='w-11/12 self-center mb-2 lg:mb-0  md:w-8/12 lg:flex lg:flex-col px-4 pb-4 bg-blue-700 lg:w-72 lg:h-80' >
                   
                    <h2 className='flex btfillh2 px-8 pt-8 'data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">What</h2>
                    <h2 className='flex btfillh2  px-8' data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">we</h2>
                    <h2 className='flex btfillh2 px-8 ' data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">do</h2>
                </div>
                <div className='w-11/12 self-center mb-2 lg:mb-0  md:w-8/12 lg:flex lg:flex-col px-4 pb-4 bg-blue-500 lg:w-72 lg:h-80' >
                    <h2 className='flex bth2 self-center'data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">infrastructure</h2>
                    <p className='flex text-white font-normal  lg:text-base md:text-sm text-xs text-left leading-relaxed' data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">
                    Extensive and efficient infrastructure is very much essential and
                    vital for development. We get to see no effective modes of
                    transport to rural areas. the businesses find it difficult to
                    extend their distribution channel to rural areas resulting in lack
                    of suitable jobs for rural residents.
                    </p>
                </div>
                <div className='w-11/12 self-center mb-2 lg:mb-0  md:w-8/12 lg:flex lg:flex-col px-4 pb-4 bg-cyan-700 lg:w-72 lg:h-80' >
                    <h2 className='flex bth2 self-center'data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">environment</h2>
                    <p className='flex text-white font-normal  lg:text-base md:text-sm text-xs text-left leading-relaxed'data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">
                    We believe a clean environment and strong conservation programmes
                    are important to society. So concrete policies for reducing
                    emissions of gases like Carbon dioxide, Methane, Nitrous oxide,
                    Hydrofluro Carbons should be taken.
                    </p>
                </div>
                <div className='w-11/12 self-center mb-2 lg:mb-0  md:w-8/12 lg:flex lg:flex-col px-4 pb-4 bg-cyan-600 lg:w-72 lg:h-80' >
                    <h2 className='flex bth2 self-center 'data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">enery</h2>
                    <p className='flex text-white font-normal  lg:text-base md:text-sm text-xs text-left leading-relaxed'data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">
                    Extensive and efficient infrastructure is very much essential and
                    vital for development. We get to see no effective modes of
                    transport to rural areas. The businesses find it difficult to
                    extend their distribution channel to rura areas resulting in lack
                    of suitable jobs for rural residents.
                    </p>
                </div>

            </div>
            <div className='flex w-full flex-col lg:flex-row p-2 justify-evenly overflow-hidden '>
                <div className='w-11/12 self-center mb-2 lg:mb-0  md:w-8/12 lg:flex lg:flex-col px-4 pb-4 bg-sky-950 lg:w-72 lg:h-80 overflow-y-scroll'  >
                    <h2 className='flex bth2  self-center 'data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">Women</h2>
                    <h2 className='flex bth2 pb-2 self-center ' data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">Empowerment</h2>
                    <p className='flex text-white font-normal  lg:text-base md:text-sm text-xs text-left leading-relaxed' data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">
                    We all know that women play a vital role in our society even our
                    nation is referred to as MOTHER INDIA. We stand for women
                    empowerment by
                    </p>
                    <ul className='flex flex-col' data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">
                        <li className='flex text-white text-left lg:text-base md:text-sm text-xs   w-full leading-relaxed'><span><BiChevronRight className='text-left text-2xl' /></span>Promoting women role in democratic governance.</li>
                        <li className='flex text-white text-left lg:text-base md:text-sm text-xs   w-full leading-relaxed' ><span><BiChevronRight className='text-left text-2xl' /></span> Promoting gender equality and thereby reduce poverty among
                            women.
                        </li>
                        <li className='flex text-white text-left lg:text-base md:text-sm text-xs   w-full   leading-relaxed' ><span><BiChevronRight className='text-left  text-2xl' /></span>Stopping violence against women.</li>
                    </ul>
                </div>
                <div className='w-11/12 self-center mb-2 lg:mb-0  md:w-8/12 lg:flex lg:flex-col px-4 pb-4 bg-blue-600 lg:w-72 lg:h-80' >
                    <h2 className='flex bth2 self-center'data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">law & order</h2>
                    <p className='flex text-white font-normal  lg:text-base md:text-sm text-xs text-left leading-relaxed'data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">
                    The maintenance of law & order is basic and necessary
                    responsibility of each citizen and Governament.
                    </p>
                </div>
                <div className='w-11/12 self-center mb-2 lg:mb-0  md:w-8/12 lg:flex lg:flex-col px-4 pb-4 bg-blue-500 lg:w-72 lg:h-80 overflow-y-scroll'>
                    <h2 className='flex bth2 self-center'data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">civil defence</h2>
                    <p className='flex text-white font-normal  lg:text-base md:text-sm text-xs text-left leading-relaxed'data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">
                    (Aria Suraksha mitra)Civil emergencies arises due to the
                    non-conventiontional threats such as natural disasters. Our civil
                    defence force should be well trained in rescue and evacuation
                    procedures and shelter management. We should be familiarized with
                    basic first aid emergency arrangements relief supply as blood,
                    water food etc. and engage in creating safer neighborhoods,
                    stronger communities.
                    </p>
                </div>
                <div className='w-11/12 self-center mb-2 lg:mb-0  md:w-8/12 lg:flex lg:flex-col px-4 pb-4 bg-blue-400 lg:w-72 lg:h-80'>
                    <h2 className='flex bth2 self-center ' data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">Social</h2>
                    <h2 className='flex bth2 self-center pb-2 'data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">responsibility</h2>
                    <p className='flex text-white font-normal  lg:text-base md:text-sm text-xs text-left leading-relaxed'data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">
                    Social responsibilities are for all citizens as their duties now
                    the Government as come up with a non-mandatory policy that
                    businesses registered under companies act 1956 should spend 2% of
                    their profit as social responsibilities.
                    </p>
                </div>

            </div>
            
        </div>
        <div className='w-full  lg:px-16 lg:pb-6 pb-2' >
            <p className='w-full  flex justify-center lg:-ml-28  lg:justify-end text-sm md:text-base lg:text-base  font-semibold hover:text-bottom cursor-pointer  text-gray-700' data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in">Visit our Website</p>
            <a
            href="http://priyadarshinitrust.org/"
            target="_blank"
            className='w-full flex justify-center lg:-ml-2 lg:justify-end text-sm md:text-base lg:text-base  font-semibold hover:text-bottom cursor-pointer  text-gray-700' data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in"
            >                
            http://priyadarshinitrust.org/
            </a>
        </div>
      
    </>
  )
}

export default ServiceThreeCharitable
