import React , {useState} from 'react';
import emailjs from 'emailjs-com';


const ContactForm = () => {

    const [formData, setFormData] = useState({
      first: '',
      last: '',
      company: '',
      email: '',
      mobile: '',
      landline: '',
      category: 'optionss',
      message: '',
      check:false,
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const fieldValue = type === 'checkbox' ? checked : value;
        setFormData((prevData) => ({ ...prevData, [name]: fieldValue }));
      };
      const handleSubmit = (e) => {
        e.preventDefault();
    
        const serviceId = 'service_u87x6do'; 
        const templateId = 'template_g9jyw9s'; 
        const publicKey = 'ab3qUhwvhzC7_Ky60'; 
    
        emailjs.send(serviceId, templateId, formData, publicKey)
        .then((response) => {
          console.log('Email sent successfully!', response);
          alert('Email sent successfully!');
        })
        .catch((error) => {
          console.error('Error sending email:', error);
          alert('Error sending email! Please check the console for details.');
        });
    
    
        setFormData({
          first: '',
          last: '',
          company: '',
          email: '',
          mobile: '',
          landline: '',
          category: 'optionss',
          message: '',
          check: false,
        });
      };
    

  return (
   <>
         <div className='w-full flex justify-center  flex-col py-4 px-6 md:py-8 md:px-28'>
          <h2 className='flex justify-start py-2 md:py-4 lg:text-2xl md:text-xl text-lg font-semibold hover:text-bottom leading-relaxed'>Have a question?</h2>
          <p className='flex text-left pb-2 md:pb-4 lg:text-lg md:text-base text-sm  lg:w-9/12 w-full  leading-relaxed'>Our Experts will help you with the right solution!</p>

        <form onSubmit={handleSubmit}>
            <div className='md:flex py-2 md:py-4 justify-between '>
            <div className='  w-12/12 pb-2 md:pb-0 md:w-5/12 '>
                <span>
                <label className="required  text-left pb-4 lg:text-base mg:text-sm text-xs  lg:w-9/12 w-full  leading-relaxed ">First Name</label>
                </span>
                <input
                  type='text'  
                  name='first' 
                  className=' relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-sm font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary' 
                  placeholder='First Name' 
                  value={formData.first} 
                  onChange={handleChange} 
                  required 
                />
            </div>
            <div className='w-12/12 pb-2 md:pb-0 md:w-5/12'>
                <span>
                <label className="required text-left pb-4 lg:text-base md:text-sm text-xs  lg:w-9/12 w-full  leading-relaxed   ">Last Name</label>
                </span>
                <input
                  type ='text'  
                  name='last'
                   className='relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-sm font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary' 
                   placeholder='Last Name'
                   value={formData.last} 
                   onChange={handleChange} 
                    required
                 />
            </div></div>
            <div className='md:flex  md:py-4 justify-between '>
            <div className='w-12/12 pb-2 md:pb-0 md:w-5/12'>
                <span>
              <label className="required text-left pb-4 lg:text-base md:text-sm text-xs  lg:w-9/12 w-full  leading-relaxed  ">Company</label>
              </span>
              <input 
                type='text'  
                name='company' 
                className='relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-sm font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary' 
                placeholder='Company' 
                value={formData.company} 
                onChange={handleChange} 
                required
              />
            </div>
            <div className='w-12/12 pb-2 md:pb-0 md:w-5/12'>
              <label className="required text-left pb-4 lg:text-base md:text-sm text-xs  lg:w-9/12 w-full  leading-relaxed ">Work Email Address</label><br></br>
              <input 
                type='email'  
                name='email' 
                className='relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-sm font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary' 
                placeholder='e.g : xyz@gmail.com' 
                value={formData.email} 
                onChange={handleChange} 
                required
              />
            </div></div>
            <div className='md:flex py-2 md:py-4 justify-between '>
            <div className='w-12/12 pb-2 md:pb-0 md:w-5/12'>
              <label className="required text-left pb-4 lg:text-base md:text-sm text-xs  lg:w-9/12 w-full  leading-relaxed ">Mobile number</label><br></br>
              <input 
                type='tel'  
                name='mobile' 
                className='relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-sm font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary' 
                placeholder='Mobile Number' 
                required  pattern="\d{10}"
                value={formData.mobile} 
                onChange={handleChange} 
                
             />
            </div>
            <div className='w-12/12 pb-2 md:pb-0 md:w-5/12'>
              <label className='text-left pb-4 lg:text-base md:text-sm text-xs  lg:w-9/12 w-full  leading-relaxed  '>Landline Number</label><br></br>
              <input 
                type='tel'  
                name='landline' 
                className='relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-sm font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary' 
                placeholder='Landline Number'
                value={formData.landline} 
                onChange={handleChange} 
              />
            </div></div>
            <div className='flex flex-col w-full pb-2 md:py-4'>
            <span>
              <label className='text-left pb-4 lg:text-base md:text-sm text-xs   w-full  leading-relaxed'>Enquiry Type</label>
              </span>
              <select  name="category"
                  className='flex optionss relative m-0 w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-sm font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary' 
                   value={formData.category}
                  onChange={handleChange} 
                 
              >
                  <option 
                  className='optionss'                    
                    disabled selected> ---Please Select Enquiry Type---</option>
                  <option  name='Flight Tickets' selected value={formData.FlightTickets} onChange={handleChange} >Flight Tickets</option>
                  <option  value='Group Travel'>Group Travel</option>
                  <option  value='Hotels'>Hotels</option>
                  <option  value='Visa'>Visa</option>
                  <option  value='Transport'>Transport</option>
                  <option  value='Corporate bookings'>Corporate bookings</option>
                 
              </select>
            </div>
            <div className='flex flex-col w-full py-2 md:py-4'>
                <label className='text-left lg:text-base md:text-sm text-xs   w-full  leading-relaxed'>write your enquiry below(min 300 Character)</label><br></br>
                <textarea 
                  rows='12'  
                  placeholder='---Please write your question in detail---'  
                  className='flex optionss relative m-0 w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-sm font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary' 
                  name="message"
                  value={formData.message}
                  onChange={handleChange} 
                />
            </div>
            <div className='w-full flex flex-row py-4 '>
              {console.log(formData)}
            <input 
              type='checkbox' 
                className='  h-4 w-6 md:h-6 md:w-8 flex justify-center'
              name="check"
              checked={formData.check}
              onChange={handleChange} 
             />
            <label className='text-left lg:text-base md:text-sm text-xs  flex   leading-relaxed'>Subscribe for Pawpl newsletter?</label>
            </div>
            <h4 className='text-left lg:text-base md:text-sm text-xs   w-full  leading-relaxed pb-6'>By proceeding, I agree to the website terms of use and to my personal information being handled in accordance with the privacy policy.</h4>
            <div  className='   w-1/2  md:w-1/6 class="inline-block rounded bg-primary  px-4 pb-2 pt-4 text-center text-base justify-center md:text-lg lg:text-xl font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">'>
              <input type='submit' value='Submit'/>
              </div>
        </form>
        </div>
   </>
  )
}

export default ContactForm
