import React,{useEffect} from 'react';
import servicesimage from "../static/images/serviceimage.jpg";
import AOS from 'aos';
import { useParams } from 'react-router-dom';

const ServiceHero = () => {
    


 useEffect(() => {
        AOS.init(
            {
                mirror:false,
                once:true,
                easing:'ease-out',
            }
        );
     }, [])
  return (
        <>
            
            <div className=' lg:mt-32  mt-20 w-full h-full md:flex bg-violet-100' >
            <div className='patterscircle'>
                <div className='absolute overflow-hidden'>
                    <div className='blinkOutlineAnimation  bg-design  ml-8 -mt-2 md:ml-14 md:-mt-2 lg:ml-24  lg:-mt-4 rounded-full h-14 w-14 md:h-20 md:w-20 lg:h-36 lg:w-36 opacity-[.27]'></div>
                    <div className='blinkOutlineAnimation  bg-design  -ml-4 -mt-6 md:-ml-4 md:-mt-10  lg:-ml-8 lg:-mt-16 rounded-full h-14 w-14 md:h-20 md:w-20 lg:h-36 lg:w-36 opacity-50'></div>
                    <div className='blinkOutlineAnimation bg-design ml-16 -mt-10 md:ml-24 md:-mt-12 lg:ml-44 lg:-mt-24 rounded-full h-14 w-14 md:h-20 md:w-20 lg:h-36 lg:w-36 opacity-50'></div>
                    <div className='blinkOutlineAnimation bg-design ml-4 -mt-6 md:ml-6 md:-mt-10 lg:ml-12 lg:-mt-16 rounded-full h-14 w-14 md:h-20 md:w-20 lg:h-36 lg:w-36 opacity-[.27]'></div>
                </div>
            </div>
                
                <div className='w-full px-12 pt-20  md:px-0 md:pt-0 md:flex py-8  md:flex-col md:basis-1/2 justify-center md:ml-28 md:mt-12 lg:ml-72 lg:mt-24' data-aos="zoom-out" data-aos-duration="3000" data-aos-offset="200">
                    <h1 className='w-full text-5xl md:text-6xl lg:text-8xl font-extrabold text-gray-700 justify-start  flex   '>Our </h1>
                    <h1 className='w-full  text-5xl md:text-6xl lg:text-8xl font-extrabold text-gray-700 justify-start flex'>Services</h1>
                </div>
                <div className='w-full px-4 pb-4 md:flex md:py-8 md:pr-8 md:flex-col md:basis-1/2 justify-center'>
                    <img src={servicesimage} alt="serviceimg" className="w-full h-fit rounded-3xl saturate-100 " />
                </div>
            </div>
        </>
  )
}

export default ServiceHero
