import React from 'react'
import ServiceHero from '../../components/ServiceHero'
import ServicesOneTravel from '../../components/ServicesOneTravel'
import ServiceTwoRoyal from '../../components/ServiceTwoRoyal'
import ServiceThreeCharitable from '../../components/ServiceThreeCharitable'
import Footer from '../../components/Footer'
import NavBar from '../../components/NavBar'

const Service = () => {
  return (
    <>
        <NavBar />
        <ServiceHero />
        <ServicesOneTravel />
        <ServiceTwoRoyal />
        <ServiceThreeCharitable />
        <Footer />
    </>
  )
}

export default Service
