import React,{useEffect} from 'react'

import {BiChevronRight } from 'react-icons/bi';
import flightImage from '../static/images/flightfinal1.png';
import AOS from 'aos';


const ServicesOneTravel = () => {

 

    useEffect(() => {
        AOS.init(
            {
                mirror:false,
                once:true,
                easing:'ease-out',
            }
        );
      }, [])
  return (
   <>
      
        <div className='w-full h-full justify-center md:flex p-10 overflow-hidden' >
            <div className='w-7/12 text-left self-center flex md:w-3/12 lg:w-72 flex-col h-full border-2 border-blue-400 p-4  md:p-8 lg:p-12 rounded-3xl'>
                <h4 className='w-full  md:flex text-blue-400 text-3xl md:text-3xl lg:text-5xl  font-bold md:pb-2 lg:pb-4 pt-4' >1.</h4>
                <h4 className='w-full md:flex  text-blue-400 text-2xl md:text-2xl lg:text-4xl  font-bold md:leading-10'>Travel</h4>
                <h4 className='w-full md:flex text-blue-400 text-2xl md:text-2xl lg:text-4xl  font-bold md:pb-4 md:leading-10' >Services</h4>
            </div>
            <div className=' w-full -mb-16 md:mb-0 pt-5 md:p-0 md:flex md:w-9/12 lg:w-7/12 h-full  justify-items-end '  data-aos-duration="3000" data-aos-offset="100" data-aos="slide-left"     >
            
                <img src={flightImage} alt="FlightImage" className=" w-full h-1/2 mb-4 md:mb-0 md:h-auto md:w-10/12 saturate-200  " data-aos-duration="3000" data-aos-offset="100"  data-aos="slide-left" />
            </div>
            </div>
            <div className='w-full lg:flex  justify-evenly p-4 md:p-6 overflow-hidden'>
                <div className='w-full lg:flex mb-10 lg:mb-0 flex-col gap-6 rounded-tr-4mxl rounded-bl-4mxl md:rounded-tr-4xl  md:rounded-bl-4xl lg:w-5/12 justify-start p-4 md:p-12 bg-blue-100 drop-shadow-lg ' data-aos-duration="3000" data-aos-offset="200" data-aos="fade-right" >
                    <h2 className='flex  pb-2 lg:pb-0 font-semibold text-sm md:text-base lg:text-lg  'data-aos-duration="3000" data-aos-offset="200"  data-aos="fade-up">Retail Travel Services</h2>
                    <p className='flex  pb-2 lg:pb-0 font-normal  lg:text-base md:text-sm text-xs text-left leading-relaxed' data-aos-duration="3000" data-aos-offset="200" data-aos="fade-up">
                        At Pawpl Travel Consultants, we understand that each traveler has
                        unique preferences and requirements. From booking flights and accommodations to organizing
                        guided tours and activities, our team of experts ensures that your
                        journey is seamless and unforgettable. We take care of all the
                        details so that you can focus on making lasting memories.
                    </p>
                    <p className='w-full flex text-xs md:text-sm lg:text-base text-left   text-gray-700 hover:text-bottom cursor-pointer' data-aos-duration="3000" data-aos-offset="200" data-aos="fade-up">Visit Website</p>
                    <a className='w-full flex text-xs md:text-sm lg:text-base text-left   text-gray-700 hover:text-bottom cursor-pointer lg:-mt-6 -mt-2 ' href="https://www.pawpl.travel" target="_blank"   data-aos-duration="3000" data-aos-offset="200" data-aos="fade-up">
                     https://www.pawpl.travel
                    </a>
                </div>
                <div className='w-full lg:flex flex-col gap-6 rounded-tl-4mxl  rounded-br-4mxl md:rounded-tl-4xl  md:rounded-br-4xl lg:w-5/12 justify-end  p-4 md:p-12 text-white bg-sky-500 drop-shadow-lg' data-aos-duration="3000" data-aos-offset="200" data-aos="fade-left"  >
                    <h2 className='w-full pb-2 lg:pb-0 text-left flex font-semibold text-sm md:text-base lg:text-lg  'data-aos-duration="3000" data-aos-offset="200" data-aos="fade-up" >Corporate travel Services</h2>
                    <p className='flex  pb-2 lg:pb-0 text-left font-normal lg:text-base md:text-sm text-xs leading-relaxed' data-aos-duration="3000" data-aos-offset="200"data-aos="fade-up">
                        We recognize the fast and demanding nature of corporate travel. With
                        our corporate travel services, we strive to make your bussiness
                        trips efficient and hassel-free. our dedicated team of professionals
                        understands the importance of time management, cost optimization and
                        personalized service. From arranging flights and accommodations to
                        handling visa requirements and ground transportation, we ensure that
                        your business travel experience is smooth and productive.
                            </p>
                            <p  className='w-full  flex text-left text-xs md:text-sm lg:text-base hover:text-bottom text-white-900 cursor-pointer '  data-aos-duration="3000" data-aos-offset="200" data-aos="fade-up">Visit Website</p>
                    <a className='w-full  flex text-left text-xs md:text-sm lg:text-base hover:text-bottom text-white-900 cursor-pointer lg:-mt-6 -mt-2' href="https://corp.pawpl.travel" target="_blank"  data-aos-duration="3000" data-aos-offset="200" data-aos="fade-up">
                        https://corp.pawpl.travel
                    </a>
                </div>
                
            </div>
            <div className='w-full  lg:flex   justify-evenly p-6 overflow-hidden'>
                <div className='w-full lg:flex mb-10 lg:mb-0 lg:flex-col gap-6 rounded-tl-4mxl  rounded-br-4mxl md:rounded-tl-4xl  md:rounded-br-4xl lg:w-5/12 justify-start  p-4 md:p-12 text-white  bg-sky-500 drop-shadow-lg' data-aos-duration="3000" data-aos-offset="200" data-aos="fade-right"  >
                    <h2 className='w-full pb-2 lg:pb-0 lg:flex text-left font-semibold text-base lg:text-lg  'data-aos-duration="3000" data-aos-offset="200" data-aos="fade-up">Leisure Travel services</h2>
                    <p className='w-full pb-2 lg:pb-0 lg:flex font-normal lg:text-base md:text-sm text-xs text-left leading-relaxed' data-aos-duration="3000" data-aos-offset="200" data-aos="fade-up">
                        Are you dreaming of a dreamy vacation or an adventure filled
                        getaway? Pawpl Travel Consultants specializes in creating tailor
                        made leisure travel experiences that cater to your interests and
                        desires. whether you want to relax on a tropical beach, explore
                        historical landmarks, or embark on thrilling adventures, our travel
                        experts will curate the perfect itinerary for you. We take care od
                        all the logistics, allowing you to immerse yourself in the joy of
                        travel.
                    </p>
                    <p className='w-full flex text-xs md:text-sm lg:text-base text-left text-white-900 hover:text-bottom cursor-pointer' data-aos-duration="3000" data-aos-offset="200" data-aos="fade-up">Visit Website</p>
                    <a className='w-full flex text-xs md:text-sm lg:text-base text-left  text-white-900 hover:text-bottom cursor-pointer lg:-mt-6 -mt-2 ' href="https://www.pawpl.travel" target="_blank"   data-aos-duration="3000" data-aos-offset="200" data-aos="fade-up">
                     https://www.pawpl.travel
                    </a>
                   
                </div>
                <div className='w-full lg:flex flex-col  rounded-tr-4mxl  rounded-bl-4mxl md:rounded-tr-4xl  md:rounded-bl-4xl lg:w-5/12 justify-end p-4 md:p-12 bg-blue-100 drop-shadow-lg' data-aos-duration="3000" data-aos-offset="200" data-aos="fade-left"  >
                    <h2 className='flex pb-2 lg:pb-6 font-semibold text-base lg:text-lg  ' data-aos-duration="3000" data-aos-offset="200" data-aos="fade-up">MICE Service</h2>
                    <p className='flex pb-2 lg:pb-0 font-normal lg:text-base md:text-sm text-xs text-left leading-relaxed' data-aos-duration="3000" data-aos-offset="200" data-aos="fade-up">
                        At Pawpl, we understand the importance of providing top-notch
                        MICE(Meetings, Incentives, Conferences and Exhibitions) services to
                        ensure the success of your events. Whether you're planning a
                        corporate meeting, and incentive trip, a conference, or an
                        exhibition, our comprehensive MICE service is designed to meet all
                        your needs and exceed your expectations.
                    </p>
                    <h4 className=' md:pl-36  lg:pl-0 flex md:self-center  w-full lg:w-9/12 lg:text-base md:text-sm text-xs' data-aos-duration="3000" data-aos-offset="200" data-aos="fade-up" >What we do in MICE Programme</h4>
                    <ul className='md:pl-36  lg:pl-0 flex  flex-col items-center' data-aos-duration="3000" data-aos-offset="200" data-aos="fade-up" >
                        <li className='flex items-center lg:text-base md:text-sm text-xs  lg:w-9/12 w-full leading-relaxed' > <BiChevronRight  /> Event Planning and Management</li>
                        <li className='flex items-center lg:text-base md:text-sm text-xs  lg:w-9/12 w-full leading-relaxed'> <BiChevronRight  /> Venue Selection and Setup</li>
                        <li className='flex items-center lg:text-base md:text-sm text-xs  lg:w-9/12 w-full leading-relaxed' ><BiChevronRight  />Accommodation and Travel</li>
                        <li className='flex items-center lg:text-base md:text-sm text-xs  lg:w-9/12 w-full leading-relaxed' ><BiChevronRight  />Program Design and Content</li>
                        <li className='flex text-left md:items-center lg:text-base md:text-sm text-xs  lg:w-9/12 w-full leading-relaxed' ><BiChevronRight  />Technology and Audiovisual Support</li>
                        <li className='flex text-left md:items-center lg:text-base md:text-sm text-xs  lg:w-9/12 w-full leading-relaxed'><BiChevronRight  />Exhibition and Spondorship Management</li>
                        <li className='flex items-center lg:text-base md:text-sm text-xs  lg:w-9/12 w-full leading-relaxed' ><BiChevronRight  />On-site Coordination and Support</li>
                        <li className='flex items-center lg:text-base md:text-sm text-xs  lg:w-9/12 w-full leading-relaxed'  ><BiChevronRight  />Post-Event Evaluation and Analysis</li>
                    </ul>
                </div>
                
            </div>
        
   </>
  )
}

export default ServicesOneTravel
