import React from 'react';
import NavBar from '../../components/NavBar';
import HomeHero from '../../components/HomeHero';
import Footer from '../../components/Footer'
import HomeAboutUs from '../../components/HomeAboutUs';
import HomeBussiness from '../../components/HomeBussiness';
import HomeAutoMobiles from '../../components/HomeAutoMobiles';
import HomeShowMore from '../../components/HomeShowMore';
import HomeWhyChooseUs from '../../components/HomeWhyChooseUs';

const HomePage = () => {


  return (
    <>
      
      <NavBar />
      <HomeHero />
      <HomeAboutUs />
      <HomeBussiness />
      <HomeAutoMobiles />
      <HomeShowMore />
      <HomeWhyChooseUs />
      <Footer />
      
    </>
  )
}

export default HomePage
