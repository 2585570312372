import React,{useEffect} from 'react'
import {BiChevronRight } from 'react-icons/bi';
import {BiSolidPhoneCall} from 'react-icons/bi'
import { HiMail } from 'react-icons/hi'
import bikeImage from '../static/images/royalenfield1.png';
import royallogo from "../static/images/royal logo.png";
import AOS from 'aos';

const ServiceTwoRoyal = () => {
 

    useEffect(() => {
        AOS.init(
            {
                mirror:false,
                once:true,
                easing:'ease-out',
            }
        );
      }, [])
      const handleEmailClick = () => {
        const emailAddress = 'pawpl.gonda@dealer.roayalenfield.com  ';
        window.location.href = `mailto:${emailAddress}`;
      };
      const handleDivClick = () => {
        const mapURL = 'https://www.google.com/maps/place/Royal+Enfield+Showroom+-+Pawpl+Ventures+Pvt+Ltd/@27.1306441,81.6968711,17z/data=!3m1!4b1!4m6!3m5!1s0x399991b5ba0ce381:0xbc53c3cacfa5242!8m2!3d27.1306441!4d81.6968711!16s%2Fg%2F11q99y_v7b?entry=ttu';
        window.open(mapURL, '_blank');
      };
       
  return (
    <>
        <div className='w-full h-full flex flex-col-reverse  justify-center md:flex-row md:flex p-12 overflow-hidden'  >
            <div className=' w-full -mb-12 md:mb-0 pt-6 md:pt-10 md:p-0 md:flex md:w-9/12 lg:w-7/12 -mt-10 h-full justify-items-start overflow-hidden' data-aos-duration="3000" data-aos-offset="200" data-aos="slide-right">
               
                <img src={bikeImage} alt="FlightImage" className="w-full h-1/2 md:h-auto mt-4 md:mt-0 md:w-10/12 saturate-200" data-aos-duration="3000" data-aos-offset="200"  data-aos="slide-right"  />
            </div>
            <div className='w-7/12 text-left flex md:w-3/12 lg:w-80 justify-center  flex-col h-full border-2 border-blue-400 p-4  md:p-8 lg:p-12 rounded-3xl'>
                <h4 className='w-full  md:flex text-blue-400 text-3xl md:text-3xl lg:text-5xl  font-bold md:pb-2 lg:pb-4 pt-4' >2.</h4>
                <h4 className='w-full md:flex  text-blue-400 text-2xl md:text-2xl lg:text-4xl  font-bold md:pb-1 md:leading-10'>Royal</h4>
                <h4 className='w-full md:flex  text-blue-400 text-2xl md:text-2xl lg:text-4xl  font-bold md:pb-1 md:leading-10'>Enfield</h4>
                <h4 className='w-full md:flex text-blue-400 text-2xl md:text-2xl lg:text-4xl  font-bold md:pb-4 md:leading-10' >Services</h4>
            </div>
        </div >
        <div className='w-full lg:flex px-4 py-4 lg:px-8 lg:py-4 justify-evenly     lg:divide-x divide-gray-700 divide-solid overflow-hidden'>
            <div className='w-full flex flex-col text-left lg:w-5/12  pl-4  py-4 lg:pl-6 lg:py-6 overflow-hidden 'data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-out-right">
                <h2 className='flex  pb-2 lg:pb-0 font-semibold text-sm md:text-base lg:text-lg overflow-hidden 'data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-out-right">Extensive Range of</h2>
                <h2 className='flex  pb-4  font-semibold text-sm md:text-base lg:text-lg overflow-hidden 'data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-out-right">Royal Enfield Motorcycles</h2>
                <p className='flex   font-normal  lg:text-base md:text-sm text-xs text-left leading-relaxed overflow-hidden'data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-out-right">
                    At Pawpl Ventures ltd, we offer an extensive lineup of Royal Enfield
                    motorcycles to suit every rider's preferences and riding style. From
                    the classic and iconic to the versatile Royal Enfield Bullet and
                    Royal Enfield classic to the versatile Royal Enfield Himalayan
                    adventure bike, we have something for everyone. Experience the joy
                    of riding a Royal Enfield that perfectly captures the essence of
                    motorcycling.
                </p>
            </div>
           
            <div className='w-full flex flex-col text-left lg:w-5/12  pl-4  py-4 lg:pl-16 lg:py-6 overflow-hidden'data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-out-left">
                <h2  className='flex  pb-4 lg:pb-6  font-semibold text-sm md:text-base lg:text-lg overflow-hidden 'data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-out-left">Superior Customer Service</h2>
                <p className='flex  font-normal  lg:text-base md:text-sm text-xs text-left leading-relaxed overflow-hidden'data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-out-left">
                    We prioritize customer satisfaction above all else. Our dedicated
                    team of sales professionals is passionate about motorcycles and
                    strives to provide you with exceptional service. We are here to
                    assist you throughout your motorcycle purchasing journey, from
                    helping you choose the right model to providing after sales support
                    and servicing. your satisfaction is our top priority.
                </p>
            </div>
        </div>
        <div className='w-full lg:flex  px-4 justify-evenly overflow-hidden'>
            <div className='w-full flex flex-col text-left lg:w-3/12  pl-4  lg:pl-6 lg:py-6 overflow-hidden 'data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-out-right">
                <h2 className='flex  pb-4  font-semibold text-sm md:text-base lg:text-lg overflow-hidden 'data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-out-right">Genuine spare parts and Accessories</h2>
                <p className='flex  pb-4 font-normal  lg:text-base md:text-sm text-xs text-left leading-relaxed overflow-hidden'data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-out-right">
                    As an authorized Royal Enfield dealer, we only offer genuine spare
                    parts and accessories. We understand the importance of maintaining
                    the performance and reliability of your motorcycle. Whether you need
                    replacement parts or want to personalize your ride with accessories,
                    we have you covered. Our inventory includes a wide range of
                    authentic Royal Enfield parts and accessories to enhance your riding
                    experience.
                </p>
            </div>
            <div className='hidden lg:flex w-96 justify-center self-center place-content-center overflow-hidden'>
                <img src={royallogo} alt="royallogo" className="h-1/2 w-full relative left-10 "data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-in"/>
            </div>
           
            <div className='w-full flex flex-col text-left lg:w-3/12 pl-4   lg:pl-6 lg:py-6 overflow-hidden' data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-out-left">
                <h2  className='flex  pb-4  font-semibold text-sm md:text-base lg:text-lg overflow-hidden ' data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-out-left">Skilled and Certified Technicians</h2>
                <p className='flex  font-normal  lg:text-base md:text-sm text-xs text-left leading-relaxed overflow-hidden' data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-out-left">
                    Your Royal Enfield deserves the best care, and our dealership is
                    equipped with skilled and certified technicians who are well-versed
                    in servicing and maintaining Royal Enfield motorcycles. We have
                    state-of-the-art service facilities to ensure that your motorcycle
                    receives top-notch care and attention. Rest assured that your prized
                    possession is in capable hands when it comes to servicing, repairs
                    or customization.
                </p>
            </div>
        </div>
        <div className='w-full lg:flex  px-4 lg:px-8 lg:py-4 justify-evenly     lg:divide-x divide-gray-700 divide-solid overflow-hidden'>
            <div className='w-full flex flex-col text-left lg:w-5/12  pl-4  py-4 lg:pl-6 lg:py-6 overflow-hidden ' data-aos-duration="3000" data-aos-offset="200"  data-aos="zoom-out-right">
                <h2 className='flex  pb-4  font-semibold text-sm md:text-base lg:text-lg overflow-hidden  'data-aos-duration="3000" data-aos-offset="200"  data-aos="zoom-out-right">Financing and Insurance Options</h2>
                <p className='flex   lg:pb-0 font-normal  lg:text-base md:text-sm text-xs text-left leading-relaxed overflow-hidden' data-aos-duration="3000" data-aos-offset="200"  data-aos="zoom-out-right">
                    As an authorized Royal Enfield dealer, we only offer genuine spare
                    parts and accessories. We understand the importance of maintaining
                    the performance and reliability of your motorcycle. Whether you need
                    replacement parts or want to personalize your ride with accessories,
                    we have you covered. Our inventory includes a wide range of
                    authentic Royal Enfield parts and accessories to enhance your riding
                    experience.
                </p>
            </div>
           
            <div className='w-full flex flex-col text-left lg:w-5/12 pl-4  py-4 lg:pl-16 lg:py-6 overflow-hidden' data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-out-left">
                <h2   className='flex  pb-4  font-semibold text-sm md:text-base lg:text-lg overflow-hidden ' data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-out-left">Visit PAWPL</h2>
                <p className='flex  lg:pb-0 font-normal  lg:text-base md:text-sm text-xs text-left leading-relaxed overflow-hidden' data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-out-left">
                    Visit Pawpl Ventures Ltd today and discover the world of Royal
                    Enfield motorcycles. Immerse yourself in the legacy and craftmanship
                    that makes Royal Enfield a symbol of excellence on the roads. Our
                    dealership is committed to providing you with an unparalleled
                    experience as you embark on your Royal Enfield journey. contact us
                    or visit our showroom to explore our range of motorcycles,
                    accessories, and services. Get ready to ignite your passion for
                    riding with Pawpl Ventures Ltd your trusted Royal Enfield dealer in
                    Uttar Pradesh!
                </p>
            </div>
        </div>
      <div className=' text-left flex w-full justify-center  flex-col h-full  p-8  md:p-8 lg:p-14 lg:ml-8'>
        <h2 className='flex  pb-4  font-semibold text-sm md:text-base lg:text-lg' data-aos-duration="2000" data-aos-offset="200" data-aos="zoom-out" >Pawpl Ventures Pvt Ltd</h2> 
        <div class="md:flex  lg:gap-32 ">
           
           <div className='flex-col' data-aos-duration="2000" data-aos-offset="200" data-aos="zoom-out">
            <p className='flex  lg:pb-0 font-normal font-semibold lg:text-base md:text-sm text-xs text-left' data-aos-duration="2000" data-aos-offset="200" data-aos="zoom-out">Address Details</p>
            <p className='flex  lg:pb-0 font-normal  lg:text-base md:text-sm text-xs text-left' data-aos-duration="2000" data-aos-offset="200" data-aos="zoom-out">Sakroura Chouraha</p>
            <p className='flex  lg:pb-0 font-normal  lg:text-base md:text-sm text-xs text-left'data-aos-duration="2000" data-aos-offset="200" data-aos="zoom-out">Lucknow Gonda Highway</p>
            <p className='flex  lg:pb-0 font-normal  lg:text-base md:text-sm text-xs text-left' data-aos-duration="2000" data-aos-offset="200" data-aos="zoom-out">Colonelganj</p>
            <p className='flex  lg:pb-0 font-normal  lg:text-base md:text-sm text-xs text-left' data-aos-duration="2000" data-aos-offset="200" data-aos="zoom-out">Gonda</p>
           </div>
           <div>
           <div className='flex-col '>
                <p className='flex md:mt-0 mt-2 lg:pb-0 font-normal font-semibold lg:text-base md:text-sm text-xs text-left' data-aos-duration="2000" data-aos-offset="200" data-aos="zoom-out">Store Manager</p>
                <p className='flex  lg:pb-0 font-normal  lg:text-base md:text-sm text-xs text-left' data-aos-duration="2000" data-aos-offset="200" data-aos="zoom-out">Sanjay Singh</p>
            </div>
            <div className='flex-col gap-2 pt-4 cursor-pointer ' data-aos-duration="2000" data-aos-offset="200" data-aos="zoom-out">
            <p className='flex  lg:pb-0 font-normal font-semibold lg:text-base md:text-sm text-xs text-left' data-aos-duration="2000" data-aos-offset="200" data-aos="zoom-out">Phone No</p>
            <span className='flex'>
            <BiSolidPhoneCall  className=' flex md:h-6 md:w-6  h-4 w-4  pr-1 ' data-aos-duration="2000" data-aos-offset="200" data-aos="zoom-out"/> 
            <p className='flex text-left lg:text-base md:text-sm text-xs  lg:w-9/12 w-full  leading-relaxed overflow-hidden 'data-aos-duration="2000" data-aos-offset="200" data-aos="zoom-out">
               9606999321
            </p>
            </span></div>
            <div className='flex-col gap-2 pt-2' onClick={handleEmailClick} data-aos-duration="2000" data-aos-offset="200" data-aos="zoom-out">
            <p className='flex  lg:pb-0 font-normal font-semibold lg:text-base md:text-sm text-xs text-left'data-aos-duration="2000" data-aos-offset="200" data-aos="zoom-out">Email</p>
            <span className='flex'>
                <HiMail className='flex md:h-6 md:w-6 h-4 w-4 md:mt-0 mt-1 pr-1' data-aos-duration="2000" data-aos-offset="200" data-aos="zoom-out"/>
            <p 
                className=' cursor-pointer flex text-left lg:text-base md:text-sm text-xs   w-full  leading-relaxed overflow-hidden 'data-aos-duration="2000" data-aos-offset="200" data-aos="zoom-out"
            >
                pawpl.gonda@dealer.roayalenfield.com  
            </p> 
            </span></div>
                      
            </div>
            
        </div>
        <div class='flex mt-2 pb-2 ' onClick={handleDivClick} data-aos-duration="3000" data-aos-offset="200">
        <p class='flex pr-1 border hover:shadow-md p-2 font-normal font-semibold lg:text-base md:text-sm text-xs'>Get Directions  
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 md:mt-1">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
        </p>
        </div>
      
       <div className='flex-row lg:mt-6 mt-2 overflow-hidden' >
            <p className="w-full flex text-sm md:text-base lg:text-base text-left font-semibold  text-gray-700" data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-out"> Visit our website</p>
        <a
          href="https://www.royalenfield.com/in/en/locate-us/dealers/pawpl_ventures_pvtltd/"
          target="_blank"
          className="w-full flex text-sm md:text-base lg:text-base text-left font-semibold  text-gray-700" data-aos-duration="3000" data-aos-offset="200" data-aos="zoom-out"
        >
        
        https://www.royalenfield.com/in/en/locate-us/dealers/pawpl_ventures_pvtltd/
        </a>
        </div>

       </div>
      
    </>
  )
}

export default ServiceTwoRoyal
