import React, {useEffect} from 'react'

import heroback from "../static/images/BGForMainBanner.png"
import ImgaeSlider from './ImgaeSlider'
import PawplTextEffect from './PawplTexteffect';
import AOS from 'aos';
import { Link } from 'react-router-dom';


const HomeHero = () => {
    useEffect(() => {
        AOS.init(
            {
                mirror:false,
                once:true,
                easing:'ease-out',
            }
        );
      }, [])
  return (
    <>
        <div className='lg:mt-32  mt-20 w-full h-full  pb-6  lg:flex bg-no-repeat bg-cover bg-center overflow-hidden ' style={{ backgroundImage:`url(${heroback})` }}>
            <div className='patterscircle'>
            <div className='absolute  overflow-hidden'>
                <div className='blinkOutlineAnimation  bg-design  ml-8 -mt-2 md:ml-14 md:-mt-2 lg:ml-24  lg:-mt-4 rounded-full h-14 w-14 md:h-20 md:w-20 lg:h-36 lg:w-36 opacity-[.27]'></div>
                <div className='blinkOutlineAnimation  bg-design  -ml-4 -mt-6 md:-ml-4 md:-mt-10  lg:-ml-8 lg:-mt-16 rounded-full h-14 w-14 md:h-20 md:w-20 lg:h-36 lg:w-36 opacity-50'></div>
                <div className='blinkOutlineAnimation bg-design ml-16 -mt-10 md:ml-24 md:-mt-12 lg:ml-44 lg:-mt-24 rounded-full h-14 w-14 md:h-20 md:w-20 lg:h-36 lg:w-36 opacity-50'></div>
                <div className='blinkOutlineAnimation bg-design ml-4 -mt-6 md:ml-6 md:-mt-10 lg:ml-12 lg:-mt-16 rounded-full h-14 w-14 md:h-20 md:w-20 lg:h-36 lg:w-36 opacity-[.27]'></div>
            </div>
            </div>
            <div className='w-full lg:flex p-8 lg:p-0 flex-col lg:basis-1/2 justify-center lg:ml-12 '>
                 
                 <PawplTextEffect />
                <h3 className='w-full herohead lg:flex  text-left ' data-aos="fade-right" data-aos-duration="3000" data-aos-offset="200" >Spirit of Industry Propelled by hunger for innovation</h3>
                <p className='w-full lg:flex  text-xs  lg:text-base lg:font-medium pt-2 text-left text-indigo-950' data-aos="fade-right" data-aos-duration="3000" data-aos-offset="200"> We are committed to Customer service and delivering exceptional
                    support to our valued customers since the time we are
                    incepted in 2002.
                </p>
            
                    <Link to="/service" 
                    className=" w-full  gap-0.5  flex text-design text-xs lg:text-base font-normal pt-5 lg:pt-10 lg:leading-6  lg:transform transition ease-in-out delay-75 lg:hover:translate-x-5 hover:scale-105" data-aos="zoom-out" data-aos-duration="3000" data-aos-offset="150"
                    onClick={() => {
                        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
                        }}   
                    >
                        Read More
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.8} stroke="currentColor" className="lg:w-6 lg:h-6 w-4 h-4 ">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </Link>
            
            </div>
           <div className='w-11/12 lg:flex lg:flex-col ml-4 lg:ml-0 lg:basis-1/2  pt-6 lg:pt-0 pr-6 md:pr-0 md:pl-0 lg:pr-8 lg:mx-8 pl-6 pb-6 lg:pl-0 mb-4 lg:mb-12 shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)] lg:shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.38)] rounded-3xl'>
                <ImgaeSlider />
           </div>
           
           
        </div>
        <div className='hidden lg:flex lg:-mt-20 -mt-18  p-6 justify-between'>
                <div>
                    <a href="#" className=" flex  text-design font-semibold  leading-6  hover:scale-125">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="1 0 24 24" strokeWidth={1.2} stroke="currentColor" className="lg:w-10 xl:w-11 lg:h-10 xl:h-11">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </a>
                    </div>
                <div>
                    <a href="#" className=" flex  text-design font-semibold  leading-6  hover:scale-125">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.2} stroke="currentColor" className="lg:w-10 xl:w-11 lg:h-10 xl:h-11">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </a>
                </div>
           </div>
    </>
  )
}

export default HomeHero
