import Footer from "./Footer"
import NavBar from "./NavBar"

const TermsOfUse = () => {
  return (
   <>
    <NavBar />
    <div className='w-full self-center justify-center flex-col p-6 md:p-10 lg:px-40 text-neutral-500 lg:mt-24 md:mt-16 mt-14 '>
        <h4 className='flex text-neutral-900 lg:text-4xl pb-4 md:pb-8 font-semibold md:text-3xl text-2xl  items-center hover:animate-pulse hover:text-bottom py-2 md:py-4 '>Pawpl Group Terms of Service</h4>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>This web site www.pawpl.com (the "Site") including the mobile site, Smartphone App Platforms such as iOS, Android, Windows is published and maintained by Pawpl Group Private Limited (Pawpl Group), a company incorporated and existing in accordance with the laws of the Republic of India. By accessing or using the Site or by using the Service on the Site you agree to be bound by these terms and conditions set forth below including any additional guidelines and future modifications. When you access any sub-site, webpage, hyperlink (whether belonging to an associate of Pawpl Group or otherwise) through this site, then such sub-site may have its own terms and conditions of use which is specific to such sub-site, webpage, hyperlink. The sub-sites, webpage, hyperlink may contain such additional terms and conditions of use as may be set out in such sub-sites, webpage, hyperlink.</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Pawpl Group may add to, change or remove any part of these Terms and Conditions of Use at any time, without notice. Any changes to these Terms and Conditions of Use or any terms posted on this Site apply as soon as they are posted. You shall re-visit the "Terms & Conditions" link from time to time to stay abreast of any changes that the "Site" may introduce.</p>
        <h4 className='flex text-neutral-900 lg:text-2xl pb-4 md:pb-8 font-semibold md:text-xl text-lg  items-center hover:animate-pulse hover:text-bottom py-2 md:py-4 '>Your Agreement to these Terms and Conditions for availing Service</h4>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>The Terms and Conditions is a legal contract between you being, an individual customer, user, or beneficiary of this service, and Pawpl Group Network Private Limited (Pawpl Group) having its registered office at Unit #10/28 NK Plaza 1st main 4th N Block, Dr Rajkumar Rd, Rajajinagar, Bengaluru, Bengaluru,Karnataka 560 010.</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Pawpl Group acts as an "Intermediary" solely to assist customers in gathering travel information, determining the availability of travel-related products and services, making legitimate reservations or otherwise transacting business with travel suppliers, and for facilitating travel requirements. You acknowledge that Pawpl Group merely provides intermediary services in order to facilitate services to you. Pawpl Group is not the last mile service provider to you and therefore Pawpl Group shall not be deemed to be responsible for any lack or deficiency of services provided by any person or entity including airline, hotel, activity provider or similar agency), you shall engage, hire from the content available on the Site.</p>
        <h4 className='flex text-neutral-900 lg:text-2xl pb-4 md:pb-8 font-semibold md:text-xl text-lg  items-center hover:animate-pulse hover:text-bottom py-2 md:py-4 '>Use of the Website</h4>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>As a condition of your use of this Website, you warrant that:</p>
        <ul>
            <li className=' list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>you possess the legal authority to create a binding legal obligation and enter into these Terms of Use.</li>
            <li className='list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>you will use this Website in accordance with the Terms of Use.</li>
            <li className='list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>you will use this Website to make only legitimate reservations for you or for another person for whom you are legally authorized to act.</li>
            <li className='list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>you will inform such other persons about the Terms of Use that apply to the reservations you have made on their behalf, including all rules and restrictions applicable there to.</li>
            <li className='list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>you will provide your proper and accurate Name (as per Passport or any other office ID), Address, Email ID, Cell Number . You will be solely responsible for the Information provided and in case of any error or mistake in provision of information, Pawpl Group will not be liable for the same.</li>
            <li className='list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>all information supplied by you on this Website is true, accurate, current and complete.</li>
            <li className='list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>if you have an online account with this Website, you will safeguard your login details, login ID and password and will supervise the same. You will be completely responsible for any use or misuse of your account by you and anyone other person other than you.</li>
        </ul>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Pawpl Group retains the right in its sole discretion to deny access to anyone to this Website and the services we offer, at any time without notice and for any reason, including, but not limited to, for violation of these Terms of Use.</p>
        <h4 className='flex text-neutral-900 lg:text-2xl pb-4 md:pb-8 font-semibold md:text-xl text-lg  items-center hover:animate-pulse hover:text-bottom py-2 md:py-4 '>Communication Policy of the site</h4>
        <ul>
            <li className=' list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Upon transacting on the Site, you will receive an e-mail from Pawpl Group informing the status of your transaction. The e-mail will be sent to e-mail address provided by you and Pawpl Group is not responsible towards the receipt of the said e-mail in your inbox. You will have to take due care and caution. You are responsible for entering the proper and accurate contact details including your name (as per Passport), email ID to ensure that we can effectively communicate with you.</li>
            <li className=' list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>The customer acknowledges that the SMS (Short Messaging Service) provided by Pawpl Group is an additional facility provided for the customer's convenience. It is not mandatory under the law in force to provide SMS service alerts to the customers. In case you do not receive any SMS for whatsoever reason, Pawpl Group is not liable for the same.</li>
            <li className=' list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Pawpl Group is not responsible to provide information on any change in flight schedules, cancellation, status of airline/hotel etc.</li>
            <li className=' list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Any grievance regarding the service should be communicated as per the grievance policy laid out herein.</li>
        </ul>
        <h4 className='flex text-neutral-900 lg:text-2xl pb-4 md:pb-8 font-semibold md:text-xl text-lg items-center hover:animate-pulse hover:text-bottom py-2 md:py-4 '>Site and its Content</h4>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>This Site is only for your personal use. You shall not copy, license, adapt, distribute, exchange, modify, sell or transmit any content or material from this Site, including but not limited to any text, images, audio, video or links for any business, commercial or public purpose.</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Pawpl Group grants you a non-exclusive, non-transferable, limited right to enter, view, use and transact on this Site. You agree not to interrupt or attempt to interrupt the operation of this Site in any way. Access to certain areas of the Site may only be available to registered members.</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>You understand that except for information, products or services clearly indicated as being supplied by Pawpl Group, we do not operate, control, or endorse any information, products or services on the Internet in anyway. You also understand that Pawpl Group cannot and does not guarantee or warrant that files available for downloading through the Site will be free of viruses, worms or other code that may be damaging. You are responsible for implementing procedures to satisfy your particular requirements and for accuracy of data input and output.</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Pawpl Group may add, change, discontinue, remove or suspend any other Content or services posted on this Site, including features and specifications of products described or depicted on the Site, temporarily or permanently, at any time, without notice and without liability.</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>All materials on this Site, including but not limited to audio, images, software, text, icons and such like (the "Content"), are protected by copyright under international conventions and intellectual property laws. You cannot use or license the Content, except as specified herein. You agree to follow all instructions on this Site limiting the way you may use the Content.</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>There are a number of proprietary logos, service marks and trademarks found on this Site whether owned/used by Pawpl Group or otherwise. By displaying them on this Site, Pawpl Group is not granting you any license to utilize those proprietary logos, service marks, or trademarks. Any unauthorized use of the Content may violate copyright laws, trademark laws, the laws of privacy and publicity, civil and criminal statutes.</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>The content and information on this Website (including, but not limited to, price and availability of travel services), as well as the infrastructure used to provide such content and information, is proprietary to us or our suppliers and providers.</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>While you may make limited copies of your travel itinerary (and related documents) for travel or service reservations booked through this Website, you agree not to otherwise modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell or re-sell any information, software, products, or services obtained from or through this Website.</p>
        <h4 className='flex text-neutral-900 lg:text-2xl pb-4 md:pb-8 font-semibold md:text-xl text-lg  items-center hover:animate-pulse hover:text-bottom py-2 md:py-4 '>Availability, Rules of Service</h4>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>The products and services displayed on the Site may not be available for purchase in your particular country or locality. The reference to such products and services on the Site does not imply or warrant that these products or services will be available at any time in your particular geographical location. The products and services also are subject to availability, Pawpl Group shall not be responsible if at the time of completing the transaction on the Site, the product is not available or is sold out. You should check with customer support (add hyper link) for the availability of specific products and services in your area.</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>All the products and services available on the Website are governed by the rules of service and regulations including the terms of contract of carriage, of the respective service provider. You are requested to refer to, acquaint and abide by those rules of service and regulations of the respective service provider, prior to booking with us.</p>
        <h4 className='flex text-neutral-900 lg:text-2xl pb-4 md:pb-8 font-semibold md:text-xl text-lg items-center hover:animate-pulse hover:text-bottom py-2 md:py-4 '>Visa Guidelines</h4>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Please carry a valid visa for the country you will be visiting or transiting through. Please check with the concerned airline and embassy for concerned Visa requirements!</p>
        <h4 className='flex text-neutral-900 lg:text-2xl pb-4 md:pb-8 font-semibold md:text-xl text-lg  items-center hover:animate-pulse hover:text-bottom py-2 md:py-4 '>Pawpl Group Rights</h4>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>If you send any communications or materials to Pawpl Group by electronic mail or otherwise, including any comments, data, questions, suggestions or the like, all such communications are, and will be treated by Pawpl Group, as non-confidential.</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>You hereby give up any and all claim that any use of such material violates any of your rights including moral rights, privacy rights, proprietary or other property rights, publicity rights, rights to credit for material or ideas, or any other right, including the right to approve the way Pawpl Group uses such material.</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Any material submitted to this Site may be adapted, broadcasted, changed, copied, disclosed, licensed, performed, posted, published, sold, transmitted or used by Pawpl Group anywhere in the world, in any medium, forever.</p>
        <h4 className='flex text-neutral-900 lg:text-2xl pb-4 md:pb-8 font-semibold md:text-xl text-lg  items-center hover:animate-pulse hover:text-bottom py-2 md:py-4 '>Others' rights</h4>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>If this Site contains bulletin boards, chat rooms, blogs, access to mailing lists or other message or communication facilities, you agree to use the same only to send and receive messages and materials that are proper and related thereto. By way of example and not as a limitation, you agree that when using the Site or any facility available herefrom, you shall not do any of the following:</p>
        <ul>
  <li class="list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs justify-start items-center leading-relaxed">Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others.</li>
  <li class="list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs justify-start items-center leading-relaxed">Publish, post, distribute or disseminate any defamatory, infringing, obscene, indecent or unlawful material or information.</li>
  <li class="list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs justify-start items-center leading-relaxed">Upload or attach files that contain software or other material protected by intellectual property laws (or by rights of privacy and publicity) unless the User owns or controls the rights thereto or has received all consents thereof as may be required by law.</li>
  <li class="list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs justify-start items-center leading-relaxed">Upload or attach files that contain viruses, corrupted files or any other similar software or programs that may damage the operation of another's computer.</li>
  <li class="list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs justify-start items-center leading-relaxed">Delete any author attributions, legal notices or proprietary designations or labels in any file that is uploaded.</li>
  <li class="list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs justify-start items-center leading-relaxed">Falsify the origin or source of software or other material contained in a file that is uploaded.</li>
  <li class="list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs justify-start items-center leading-relaxed">Advertise or offer to sell any goods or services, or conduct or forward surveys, contests or chain letters, or download any file posted by another user of a Forum that the User knows, or reasonably should know, cannot be legally distributed in such a manner.</li>
  <li class="list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs justify-start items-center leading-relaxed">Use this Website or its contents for any commercial purpose.</li>
  <li class="list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs justify-start items-center leading-relaxed">Make any speculative, false, or fraudulent reservation or any reservation in anticipation of demand.</li>
  <li class="list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs justify-start items-center leading-relaxed">Access, monitor or copy any content or information of this Website using any robot, spider, scraper or other automated means or any manual process for any purpose without our express written permission.</li>
  <li class="list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs justify-start items-center leading-relaxed">Violate the restrictions in any robot exclusion headers on this Website or bypass or circumvent other measures employed to prevent or limit access to this Website.</li>
  <li class="list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs justify-start items-center leading-relaxed">Take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on our infrastructure.</li>
  <li class="list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs justify-start items-center leading-relaxed">Deep-link to any portion of this Website (including, without limitation, the purchase path for any travel services) for any purpose without our express written permission or 'frame', 'mirror' or otherwise incorporate any part of this Website into any other website without our prior written authorization.</li>
  <li class="list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs justify-start items-center leading-relaxed">Not to host, display, upload, modify, publish, transmit, update or share the following:</li>
  <ul>
    <li class=" pl-4 md:pl-8 list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs justify-start items-center leading-relaxed">Information belonging to another person for which the user does not have any right.</li>
    <li class=" pl-4 md:pl-8 list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs justify-start items-center leading-relaxed">Information that is hateful, grossly harmful including harmful to minors, pedophilic, invasive of another's privacy, racially, ethnically objectionable, disparaging, encouraging money laundering or gambling.</li>
    <li class=" pl-4 md:pl-8 list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs justify-start items-center leading-relaxed">Information that infringes any Patent, Trademark, Copyright or other Proprietary rights.</li>
    <li class=" pl-4 md:pl-8 list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs justify-start items-center leading-relaxed">Information that deceives or misleads the addressee about the origin of such a message or communicates any information which is grossly offensive or menacing in nature.</li>
    <li class="pl-4 md:pl-8 list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs justify-start items-center leading-relaxed">Information that threatens the unity, integrity, defense, Security or Sovereignty of India, friendly relation with foreign states or Public order or causes incitement to the commission of any cognizable offense or insulting any other nation.</li>
    <li class=" pl-4 md:pl-8 list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs justify-start items-center leading-relaxed">Information that impersonates another person.</li>
  </ul>
</ul>

        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>If your booking or account shows signs of fraud, abuse, or suspicious activity, trip may cancel any travel or service reservations associated with your name, email address, or account, and close any associated Pawpl Group accounts. In addition,  may verify (i.e. preauthorize) your credit card. If you have conducted any fraudulent activity, Pawpl  reserves the right to take any necessary legal action and you may be liable for monetary losses to Pawpl Group, including litigation costs and damages. To contest the cancellation of a booking or freezing or closure of an account, please contact Pawpl Group Customer Service.</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Pawpl Group reserves the right to undertake all necessary steps to ensure that the security, safety and integrity of Pawpl Group's systems as well as its client's interests are and remain, well-protected. Towards this end,  may take various steps to verify and confirm the authenticity, enforceability and validity of orders placed by you.</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>If Pawpl Group, in its sole and exclusive discretion, concludes that the said transactions are not or do not reasonably appear to be, authentic, enforceable or valid, then Pawpl Group may cancel the said orders at any time up before the scheduled time of departure of the relevant flight or before the expected date of visit to any property booked through Pawpl Group.</p>
        <h4 className='flex text-neutral-900 lg:text-2xl pb-4 md:pb-8 font-semibold md:text-xl text-lg  items-center hover:animate-pulse hover:text-bottom py-2 md:py-4 '>Transmitted Material</h4>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Internet transmissions are never completely private or secure. You understand that any message or information you send to this Site may be read or intercepted by others unless there is a special notice that a particular message (for example, credit card information) is encrypted (sent in code). Sending a message to Pawpl Group does not cause Pawpl Group to have any special responsibility to you.</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>The copyright in the contents of this website belong to Pawpl Group. Accordingly Pawpl Group reserves all rights. Copying of part or all the contents of this website without permission of Pawpl Group is prohibited except to the extent that such copying/printing is necessary for the purposes of availing of the paid services provided.</p>
        <h4 className='flex text-neutral-900 lg:text-2xl pb-4 md:pb-8 font-semibold md:text-xl text-lg  items-center hover:animate-pulse hover:text-bottom py-2 md:py-4 '>Offers, Contests and interactions</h4>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>This Site may contain contests that require you to send in material or information about yourself or offer prizes. Each offers, contest and interactions has its own rules, which you must read and agree to before you participate.</p>
        <h4 className='flex text-neutral-900 lg:text-2xl pb-4 md:pb-8 font-semibold md:text-xl text-lg  items-center hover:animate-pulse hover:text-bottom py-2 md:py-4 '>Disclaimer and No Warranties</h4>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>You acknowledge that Pawpl Group is an intermediary and is not liable for any 3rd party (suppliers) obligations due to rates, quality, and all other instances, whether to any such subscribers or otherwise. You expressly agree that use of the services and the site is at your sole risk. It is your responsibility to evaluate the accuracy, completeness and usefulness of all opinions, advice, services, merchandise and other information provided through the site or on the internet generally. We do not warrant that the service will be uninterrupted or error-free or that defects in the site will be corrected.</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>The materials on this site are provided and "as available," "with all faults" basis and without warranties or representations of any kind either expressed or implied. Information, software, products, and services contained on this website, and the inclusion or offering of any products or services on this website does not constitute any endorsement or recommendation of such products or services by Pawpl Group, Pawpl Group Affiliates and their respective suppliers. The material in this Site could include technical inaccuracies or typographical errors. Pawpl Group may make changes or improvements at any time. To the fullest extent permissible pursuant to applicable law, Pawpl Group disclaims all warranties of merchantability, relating to the information and description of the hotel , air or any other travel products displayed on this website (including, without limitation, the pricing, photographs, list of amenities, general product descriptions, etc.), much of which information is provided by the respective suppliers. Hotel or any other ratings displayed on this website are intended as only general guidelines, and Pawpl Group, Pawpl Group Affiliates and their respective suppliers do not guarantee the accuracy of the ratings. Pawpl Group, Pawpl Group Affiliates and their respective suppliers make no guarantees about the availability of specific products and services. Pawpl Group may make improvements and/or changes on this website at any time. Pawpl Group and its third party suppliers, licensors, and partners do not warrant or make any representations regarding the use or the results of the use of the services, the site or any reference sites in terms of correctness, accuracy, reliability, or otherwise.</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Pawpl Group does not warrant that the functions contained in this site will be uninterrupted or error free, that defects will be corrected, or that this site or the servers that make it available are free of viruses or other harmful components, but shall endeavor to ensure your fullest satisfaction.</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Pawpl Group does not warrant or make any representations regarding the use of or the result of the use of the material on the site in terms of their correctness, accuracy, reliability, or otherwise, insofar as such material is derived from other service providers such as airlines, hotel owners and tour operators.</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>You acknowledge that this Website is provided only on the basis set out in these terms and conditions. Your uninterrupted access or use of this Website on this basis may be prevented by certain factors outside our reasonable control including, without limitation, the unavailability, inoperability or interruption of the Internet or other telecommunications services or as a result of any maintenance or other service work carried out on this Website. Pawpl Group does not accept any responsibility and will not be liable for any loss or damage whatsoever arising out of or in connection with any ability/inability to access or to use the Site.</p>
        <h4 className='flex text-neutral-900 lg:text-2xl pb-4 md:pb-8 font-semibold md:text-xl text-lg  items-center hover:animate-pulse hover:text-bottom py-2 md:py-4 '>General Provisions</h4>
        <h4 className='flex text-neutral-900 lg:text-2xl pb-4 md:pb-8 font-semibold md:text-xl text-lg  items-center hover:animate-pulse hover:text-bottom py-2 md:py-4 '>Indemnification and Limitation of Liability</h4>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>You agree to indemnify, save, and hold Pawpl Group, its affiliates, contractors, employees, officers, directors and agents harmless from any and all claims, losses, damages, and liabilities, costs and expenses, including without limitation legal fees and expenses, arising out of or related to your use or misuse of the Services or of the Site, any violation by you of this Agreement, or any breach of the representations, warranties, and covenants made by you herein.</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>In no event will Pawpl Group be liable to you for any special, indirect, incidental, consequential, punitive, reliance, or exemplary damages (including without limitation lost business opportunities, lost revenues, or loss of anticipated profits or any other pecuniary or non-pecuniary loss or damage of any nature whatsoever) arising out of or relating to (i) this agreement, (ii) the services, the site or any reference site, or (iii) your use or inability to use the services, the site (including any and all materials) or any reference sites. In no event will Pawpl Group or any of its contractors, directors, employees, agents, third party partners, licensors or suppliers' total liability to you for all damages, liabilities, losses, and causes of action arising out of or relating to (i) this Agreement, (ii) the Services, (iii) your use or inability to use the Services or the Site (including any and all Materials) or any Reference Sites, or (iv) any other interactions with Pawpl Group, however caused and whether arising in contract, tort including negligence, warranty or otherwise, exceed the amount paid by you, if any, for using the portion of the Services or the Site giving rise to the cause of action or One Thousand Rupees (Rs.1000), whichever is less.</p>
        <h4 className='flex text-neutral-900 lg:text-2xl pb-4 md:pb-8 font-semibold md:text-xl text-lg  items-center hover:animate-pulse hover:text-bottom py-2 md:py-4 '>Privacy Policy</h4>

        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Pawpl Group believes in protecting your privacy. Please click here to review our current Privacy Policy, which also governs your use of the Website, to understand our practices: Privacy policy http://www.pawpl.com/privacy/</p>
        <h4 className='flex text-neutral-900 lg:text-2xl pb-4 md:pb-8 font-semibold md:text-xl text-lg  items-center hover:animate-pulse hover:text-bottom py-2 md:py-4 '>Notice</h4>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Pawpl Group may provide you with notices and communications by email, regular mail or postings on the Site or by any other reasonable means. Except as otherwise set forth herein, notice to Pawpl Group must be sent by courier or registered mail to and Pawpl Group Network Private Limited (Pawpl Group) having its registered office at Unit #10/28 NK Plaza 1st main 4th N Block, Dr Rajkumar Rd, Rajajinagar, Bengaluru, Bengaluru, Karnataka 560 010.</p>
        <h4 className='flex text-neutral-900 lg:text-2xl pb-4 md:pb-8 font-semibold md:text-xl text-lg  items-center hover:animate-pulse hover:text-bottom py-2 md:py-4 '>Waiver</h4>

        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>The failure of Pawpl Group to exercise or enforce any right or provision of this Agreement will not constitute a waiver of such right or provision. Any waiver of any provision of this Agreement will be effective only if in writing and signed by Pawpl Group.</p>
        <h4 className='flex text-neutral-900 lg:text-2xl pb-4 md:pb-8 font-semibold md:text-xl text-lg  items-center hover:animate-pulse hover:text-bottom py-2 md:py-4 '>Arbitration</h4>

        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Either you or We may seek any interim or preliminary relief from a court of competent jurisdiction in Mumbai, India, necessary to protect the rights or the property of Pawpl Group (or its agents, suppliers, and subcontractors), pending the completion of arbitration.</p>
        <h4 className='flex text-neutral-900 lg:text-2xl pb-4 md:pb-8 font-semibold md:text-xl text-lg  items-center hover:animate-pulse hover:text-bottom py-2 md:py-4 '>Governing Law and Forum for Disputes</h4>

       
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Except as otherwise agreed by the parties or as described in the Arbitration section above, you agree that any claim or dispute you may have against Pawpl Group must be resolved by a court located in Mumbai, India. You agree to submit to the personal jurisdiction of the courts located within Mumbai, India for the purpose of litigating all such claims or disputes. This Agreement shall be governed by the laws of India, without giving effect to any principles of conflicts of law.</p>
        <h4 className='flex text-neutral-900 lg:text-2xl pb-4 md:pb-8 font-semibold md:text-xl text-lg items-center hover:animate-pulse hover:text-bottom py-2 md:py-4 '>Grievance Policy</h4>

        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Pawpl Group strongly believes in a sincere and transparent approach to its users. You trust and love us and we would never put growth before trust. This policy document aims at minimizing instances of customer complaints, grievances and disappointments via channelized approach, review and redressal.
            You can file a grievance / share feedback if you are disappointed with the services rendered by Pawpl Group or any other issues. You can give your grievance / feedback through email or snail mail.</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>In order to make Pawpl Group redressal channels more meaningful and effective, a structured system has been put in place. This system will ensure that the complaints are redressed seamlessly and well within the stipulated timeframe.</p>

        <h4 className='flex text-neutral-900 lg:text-2xl pb-4 md:pb-8 font-semibold md:text-xl text-lg  items-center hover:animate-pulse hover:text-bottom py-2 md:py-4 '>How to reach Pawpl Group</h4>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>For Service Related Issues</p>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Level 1:</p>
        <ul>
            <li className=' list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>You can write to us at admin@pawpl.com</li>
            <li className=' list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>You can go to the main page and click on About and then click on Contact to submit your query along with the Trip ID</li>
        </ul>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Level 2:</p>
        <ul>
            <li className=' list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>If the resolution you received does not meet your expectations within 72 hours of receipt of Level 1 grievance, please write to us at admin@pawpl.com along with the Trip ID</li>
        </ul>
        <p className='flex text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Level 3:</p>

        <ul>
            <li className=' list-inside list-disc text-left pb-2 md:pb-6 lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>If you are still not satisfied (after a period of 10 days from the date of receipt of Level 2 grievance), please write to:</li>
        </ul>

        <p className='flex text-left   lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Pawpl Group Pvt.Ltd</p>

        <p className='flex text-left lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>#10/28 NK Plaza 1st main 4th N Block</p>

        <p className='flex text-left  lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'> Dr Rajkumar Rd, Rajajinagar, Bengaluru</p>

        <p className='flex text-left lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>Karnataka 560 010.</p>
        <p className='flex text-left  lg:text-base md:text-sm text-xs  justify-start items-center  leading-relaxed'>P: 24/7 support: +91 96 0699 9321</p>


       
    </div>
    <Footer />
   </>
  )
}

export default TermsOfUse
