import React from 'react';
import logoimage from "../static/images/Pawpl_logo.png";
import { Link } from 'react-router-dom';

const Footer = () => {

  return (
   <>
        <div className='w-full mx-auto h-full lg:flex bg-violet-400 justify-between max-w-8xl items-center  p-2 lg:px-8" aria-label="Global'>
            <div className=' w-full lg:w-3/12 flex-row justify-center lg:pl-20 '>
             
                <a href="#" className="w-full lg:flex justify-center    lg:p-5 ">
                    <img className=" h-20 lg:h-1/6 w-auto hover:scale-125 " src={logoimage} alt="" />
                </a>
                
            </div>
            <div className=' w-full lg:w-7/12 flex-row lg:justify-start text-left p-2 lg:p-3'>
                <h2 className='w-full lg:text-3xl md:text-2xl text-xl lg:w-9/12 flex-row  justify-start lg:p-3  hover:text-bottom'>A New Generation Company</h2>
              
                <table className='w-full table-auto justify-evenly lg:w-9/12 flex-column  lg:justify-start ' 
                 onClick={() => {
                window.scrollTo({top: 0, left: 0, behavior: 'auto'});
                }}
                >
                    <thead>
                    
                        <tr >
                           
                           <th className=' py-1 font-semibold text-base lg:text-lg underline underline-offset-4 decoration-2 text-gray-700 hover:text-bottom'>
                           <Link to="/service">
                            Bussiness
                            </Link>
                            </th>
                           
                          
                            <th className='py-1 font-semibold text-base lg:text-lg underline underline-offset-4 decoration-2 text-gray-700 hover:text-bottom '>
                                <Link to="/service">
                                CSR
                                </Link>
                            </th>
                           
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr >
                          
                            <td className='pb-0.5 font-normal text-sm lg:text-base text-gray-700 hover:text-bottom'>
                                <Link to="/service">
                                Travel & Tours
                                </Link>
                            </td>
                            
                           
                            <td  className='pb-0.5 font-normal text-sm lg:text-base text-gray-700 hover:text-bottom '>
                                <Link to="/service">
                                Charity Cause 
                                </Link>
                            </td>
                           
                           
                          
                            
                        </tr>
                        <tr>
                           
                            <td  className='pb-0.5  font-normal text-sm lg:text-base text-gray-700 hover:text-bottom '>
                                <Link to="/service">
                                Automobiles
                                </Link>
                            </td>
                           
                           
                            <td  className='pb-0.5 font-normal text-sm lg:text-base text-gray-700 hover:text-bottom'>
                                <Link to="/service">
                                Education
                                </Link>
                            </td>
                            
                        </tr>
                        <tr>
                            
                            <td  className='pb-0.5  font-normal text-sm lg:text-base text-gray-700 hover:text-bottom'>
                                <Link to="/service">
                                Leisure
                                </Link>
                            </td>
                          
                            
                        </tr>
                        <tr>
                            
                            <td className='pb-0.5 font-normal text-sm lg:text-base text-gray-700 hover:text-bottom'>
                                <Link to="/service">
                                Corporate
                                </Link>
                            </td>
                           
                            
                        </tr>
                    </tbody>
                   
                </table>
                
            </div>
            

        </div>
        <div className='w-full flex-col md:flex-row flex bg-bottom p-1 lg:p-3'>
            <div className='w-full  flex'>
                <div className='w-6/12 md:ml-4 lg:w-4/12 flex justify-start hover:scale-110 ' >
                <Link to="/privacy"  
                onClick={() => {
                window.scrollTo({top: 0, left: 0, behavior: 'auto'});
                }}
                className=" flex text-xs lg:text-base text-white  ">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.8} stroke="currentColor" className=" w-4 h-4 lg:w-6 lg:h-6 ">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                    Privacy Policy
                </Link>
                </div>
                <div className=' w-6/12  lg:w-4/12 flex justify-center hover:scale-110' >
                <Link to="/terms"
                onClick={() => {
                    window.scrollTo({top: 0, left: 0, behavior: 'auto'});
                    }}
                className=" flex text-xs lg:text-base text-white  ">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.8} stroke="currentColor" className="w-4 h-4 lg:w-6 lg:h-6 ">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                    Terms of use
                </Link>
                </div>
           </div>
           <div className='w-full lg:w-12/12 md:mr-4 flex-col-reverse  flex lg:flex-row md:pr-2 lg:pr-0 '>
            <div className=' w-full  pt-2 lg:pt-0     lg:w-8/12 flex md:justify-end lg:justify-start hover:scale-110' >
            <a href="https://jnanasoft.com/" target="_blank" className=" flex text-xs lg:text-base text-white ">
           
                Powered by Jnana Technologies @ 2023 
            </a>
            </div>
            <div className=' w-full lg:mr-10  pt-2 md:pt-0  lg:w-6/12 flex  md:justify-end hover:scale-110' >
            <a href="#" className=" flex text-xs lg:text-base text-white ">
           
                Copyright 2023 @ PAWPL Group
            </a>
            </div>
        </div>
        </div>
   </>
  )
}

export default Footer
