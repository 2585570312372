import React from 'react'
import NavBar from '../../components/NavBar'
import ContactHero from '../../components/ContactHero'
import Footer from '../../components/Footer'
import ContactForm from '../../components/ContactForm'
import ContactMap from '../../components/ContactMap'

const Contact = () => {
  return (
   <>
        <NavBar />
        <ContactHero />
        <ContactForm />
        <ContactMap />
        <Footer />
   </>
  )
}

export default Contact
